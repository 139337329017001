import { TrafficLightColours as COLOURS, publicTransportLegend } from '@src/modules/performance/shared/constants';
import { Text } from '@chakra-ui/react';
import Widget from './Widget';
import { CircleIndicator } from '../Indicators';
import { usePublicTransportKPI } from '../../context/reports-api-hooks';
import IndicatorLegend from '../IndicatorLegend';
import { roundKpiValue } from '../../shared/helpers';

export default function PublicTransportWidget() {
  const { data: publicTransportData, isLoading, isError } = usePublicTransportKPI();

  const title = 'Public Transport\n& Total Mobility';
  const description = 'Public transport passenger km\ntrend over 5 years';

  // If there is an error and no data we want to hide the widget
  if (isError && (!publicTransportData || typeof publicTransportData.value !== 'number')) return null;

  return (
    <Widget
      link="/performance/dashboard/public-transport"
      title={title}
      description={description}
      lockYear={publicTransportData?.lockYear}
      quarter={null}
      isLoading={isLoading && !publicTransportData}
      moreInfoContent={(
        <>
          <Text fontSize="sm" mb={4}>
            The average change in passenger km travelled over the last five years.
          </Text>
          <IndicatorLegend items={publicTransportLegend} />
        </>
      )}
    >
      <CircleIndicator
        size={100}
        colour={COLOURS[publicTransportData?.colour]}
        value={(publicTransportData?.value !== undefined && publicTransportData?.value) ? `${roundKpiValue(publicTransportData?.value, 5, 1)}%` : null}
        isLoading={isLoading && !publicTransportData}
      />
    </Widget>
  );
}
