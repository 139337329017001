import {
  Container,
  SimpleGrid,
  Heading,
  HStack,
  Text,
  Switch,
  Icon,
  Button,
  Box,
  IconButton,
  Flex,
  Stack,
} from '@chakra-ui/react';
import {
  DndContext, DragOverlay, PointerSensor, closestCenter,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  SortableContext, rectSortingStrategy, useSortable, arrayMove,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { selectedDatabaseIdState, useIsUserInRole } from '@transport-insights/uikit';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
  FiEye, FiEyeOff, FiRotateCw, FiSliders,
} from 'react-icons/fi';
import {
  createElement, useEffect, useRef, useState,
} from 'react';
import { useConfirm } from '@src/hooks/useConfirm';
import MainLayout from '@src/components/layouts/main/MainLayout';
import E3Widget from '@src/modules/performance/components/DashboardWidgets/E3Widget';
import SafetyWidget from '@src/modules/performance/components/DashboardWidgets/SafetyWidget';
import { useSettingsConfig } from '@src/modules/settings/context/settings-api-hooks';
import AmenityWidget from '../../components/DashboardWidgets/AmenityWidget';
import DeliveryPerformanceWidget from '../../components/DashboardWidgets/DeliveryPerformanceWidget';
import CoinvestorAssuranceWidget from '../../components/DashboardWidgets/CoinvestorAssuranceWidget';
import ActivityManagementWidget from '../../components/DashboardWidgets/ActivityManagementWidget';
import ServicePerformanceWidget from '../../components/DashboardWidgets/ServicePerformanceWidget';
import TerritorialActivityWidget from '../../components/DashboardWidgets/TerritorialActivityWidget';
import NetworkPhysicalCharacteristicsWidget from '../../components/DashboardWidgets/NetworkPhysicalCharacteristicsWidget';
import RoadNetworkUseWidget from '../../components/DashboardWidgets/RoadNetworkUseWidget';
import NetworkAvailabilityAccessibilityWidget from '../../components/DashboardWidgets/NetworkAvailabilityAccessibilityWidget';
import PublicTransportWidget from '../../components/DashboardWidgets/PublicTransportWidget';
import DeliveryWidget from '../../components/DashboardWidgets/DeliveryWidget';
import StatsWidget from '../../components/DashboardWidgets/StatsWidget';
import TemporaryTrafficManagementWidget from '../../components/DashboardWidgets/TemporaryTrafficManagementWidget';
import RoadMaintenanceWidget from '../../components/DashboardWidgets/RoadMaintenanceWidget';
import NetworkAssetManagementWidget from '../../components/DashboardWidgets/NetworkAssetManagementWidget';
import { useAllowedReportsList } from '../../hooks/useReportsList';
import { dashboardViewState, customDashboardLayoutState } from '../../state';
import InfoScroller from '../reports/components/information/InfoScroller';
import InfoContentCollapse from '../reports/components/information/InfoContentCollapse';
import StateHighwaysPotholeRepairsWidget from '../../components/DashboardWidgets/StateHighwaysPotholeRepairsWidget';
import StateHighwaysSubnetworkSelector from '../../components/StateHighwaysSubnetworkSelector';
import SourcesTable from './components/SourcesTable';
import PrintablePdf from '../../components/PrintablePdf';

const widgetsMap = {
  activityManagement: ActivityManagementWidget,
  delivery: DeliveryWidget,
  deliveryPerformance: DeliveryPerformanceWidget,
  amenityAndCondition: AmenityWidget,
  safety: SafetyWidget,
  networkPhysicalCharacteristics: NetworkPhysicalCharacteristicsWidget,
  roadNetworkUse: RoadNetworkUseWidget,
  networkAvailability: NetworkAvailabilityAccessibilityWidget,
  publicTransport: PublicTransportWidget,
  territorialActivity: TerritorialActivityWidget,
  servicePerformance: ServicePerformanceWidget,
  coinvestorAssurance: CoinvestorAssuranceWidget,
  temporaryTrafficManagement: TemporaryTrafficManagementWidget,
  efficiencyEffectivenessEconomy: E3Widget,
  roadMaintenance: RoadMaintenanceWidget,
  networkAssetManagement: NetworkAssetManagementWidget,
  stateHighwaysPotholeRepairs: StateHighwaysPotholeRepairsWidget,
};

function SortableItem(props) {
  const {
    id, children, isHidden,
  } = props;
  const {
    attributes, listeners, setNodeRef, transform, transition, isDragging,
  } = useSortable({ id, disabled: isHidden });

  const style = {
    position: 'relative',
    transform: CSS.Transform.toString(transform),
    transition,
    cursor: 'grab',
    background: isDragging ? '#f1f1f1' : 'inherit',
    border: isDragging ? '1px dashed' : 'inherit',
    borderColor: isDragging ? '#999' : 'transparent',
    borderRadius: 6,
  };

  return (
    <Box
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
    >
      <Box
        position="absolute"
        top={0}
        left={0}
        right={0}
        bottom={0}
        zIndex={100}
      />
      {isDragging ? null : children}
    </Box>
  );
}

export default function Dashboard() {
  const { data: settingsConfig } = useSettingsConfig();
  const showDashboardSwitch = (settingsConfig?.performance?.ShowDashboardSwitch ?? 'false').toLowerCase() === 'true';
  const hasDeveloperRole = useIsUserInRole('Developer');
  const viewType = useRecoilValue(dashboardViewState);
  const customDashboardLayout = useRecoilValue(customDashboardLayoutState);
  const setViewType = useSetRecoilState(dashboardViewState);
  const setCustomDashboardLayout = useSetRecoilState(customDashboardLayoutState);
  const rcaId = useRecoilValue(selectedDatabaseIdState);
  const allowedReportsList = useAllowedReportsList(rcaId);
  const allReportsList = useAllowedReportsList();
  const isCustomView = customDashboardLayout.widgetList.length > 0;
  const [isCustomising, setIsCustomising] = useState(false);
  const hideStatsWidget = rcaId === 81;
  const printableRef = useRef();

  // These are the default reports for the selected view type
  // and allowed reports for the selected RCA
  const defaultReportsList = Object.keys(allowedReportsList).filter((key) => {
    const report = allowedReportsList[key];
    if (isCustomView) {
      return report.enabled && (!report.devModeDashboard || (report.devModeDashboard && hasDeveloperRole));
    }

    return report.viewType.includes(viewType)
      && report.enabled
      && (!report.devModeDashboard || (report.devModeDashboard && hasDeveloperRole));
  });
  // This is the list of ALL reports that are enabled
  const allReports = Object.keys(allReportsList).filter((key) => allReportsList[key].enabled);
  const pageTitle = viewType === 'advanced' || isCustomView ? 'Performance Dashboard' : 'Performance Dashboard';
  const [widgetList, setWidgetList] = useState([]);
  const [allowedWidgetList, setAllowedWidgetList] = useState([]);
  const [tempShowNetworkStats, setTempShowNetworkStats] = useState(customDashboardLayout.showNetworkStats);
  const [activeId, setActiveId] = useState(null);
  // This is the list of reports that are not in the widget list
  const [hiddenReports, setHiddenReports] = useState(allReports.filter((key) => !widgetList.includes(key)));

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
  );

  const handleDragStart = (event) => {
    setActiveId(event.active.id);
  };

  const handleDragEnd = (event) => {
    const { active, over } = event;
    if (active.id !== over.id) {
      setWidgetList((items) => {
        const oldIndex = items.indexOf(active.id);
        const newIndex = items.indexOf(over.id);
        return arrayMove(items, oldIndex, newIndex);
      });
    }
    setActiveId(null);
  };

  const handleDragCancel = () => {
    setActiveId(null);
  };

  const handleSetVisible = (key) => {
    setWidgetList([...widgetList, key]);
  };

  const handleSetHidden = (key) => {
    setWidgetList(widgetList.filter((item) => item !== key));
  };

  const handleSaveLayout = () => {
    setCustomDashboardLayout({ widgetList: [...widgetList], showNetworkStats: tempShowNetworkStats });
    setIsCustomising(false);
  };

  const handleCancelCustomise = () => {
    setWidgetList(customDashboardLayout.widgetList.length > 0 ? [...customDashboardLayout.widgetList] : [...defaultReportsList]);
    setIsCustomising(false);
  };

  const { confirm, ConfirmDialog } = useConfirm();
  const handleResetLayout = async () => {
    const confirmed = await confirm({
      title: 'Are you sure you want to reset the layout?',
      body: 'The layout will be reset to the default view, this cannot be undone.',
      confirmButtonLabel: 'Reset Layout',
    });

    if (confirmed) {
      setCustomDashboardLayout({ widgetList: [], showNetworkStats: true });
      setWidgetList([...defaultReportsList]);
      setAllowedWidgetList([...defaultReportsList]);
      setIsCustomising(false);
      setViewType('basic');
    }
  };

  // Set the widget list to the saved layout if it exists
  // Otherwise set it to the default reports list
  useEffect(() => {
    if (customDashboardLayout.widgetList.length > 0) {
      const filteredList = customDashboardLayout.widgetList.filter((key) => defaultReportsList.includes(key));
      setAllowedWidgetList([...filteredList]);
      setWidgetList([...customDashboardLayout.widgetList]);
    } else {
      setAllowedWidgetList([...defaultReportsList]);
      setWidgetList([...defaultReportsList]);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewType, rcaId]);

  // Update the allowed widget list when the custom layout changes
  useEffect(() => {
    if (customDashboardLayout.widgetList.length > 0) {
      const filteredList = customDashboardLayout.widgetList.filter((key) => defaultReportsList.includes(key));
      setAllowedWidgetList([...filteredList]);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customDashboardLayout]);

  // Update the hidden reports when the widget list changes
  useEffect(() => {
    setHiddenReports(allReports.filter((key) => !widgetList.includes(key)));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [widgetList]);

  // Set view type to advanced if showDashboardSwitch is false
  useEffect(() => {
    if (settingsConfig?.performance?.ShowDashboardSwitch && !showDashboardSwitch) {
      setViewType('advanced');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settingsConfig?.performance?.ShowDashboardSwitch]);

  // this controls the expansion of the help section
  const [index, setIndex] = useState(null);

  const helpSectionRef = useRef(null);
  // triggered when the user clicks on the help icon in the title
  const expandHelpSection = () => {
    setIndex(0);
  };

  return (
    <MainLayout
      backgroundColor="gray.50"
      isCustomising={isCustomising}
      sx={{
        '@media print': {
          backgroundColor: 'white',
        },
      }}
    >
      <Container
        maxW="full"
        flexDirection="column"
        display="flex"
        height="100%"
        flexGrow={1}
        position="relative"
        sx={{
          '@media print': {
            '@page': {
              size: 'landscape',
              margin: '0',
            },
          },
        }}
      >
        {isCustomising && (
        <Flex
          position="sticky"
          right={4}
          p={2}
          pl={3}
          top={4}
          zIndex={999}
          background="white"
          boxShadow="lg"
          borderRadius="base"
          justify="space-between"
          align="center"
          mb={4}
          sx={{
            '@media print': {
              display: 'none',
            },
          }}
          className="hidden-print"
        >
          <HStack>
            <Icon as={FiSliders} boxSize={6} />
            <Text fontWeight="bold">
              Customise Layout:
              {' '}
              <Text as="span" fontWeight="normal">Drag tiles to rearrange their order, or click the eye icon to show or hide them.</Text>
            </Text>
          </HStack>
          <HStack>
            <Button
              variant="solid"
              size="sm"
              colorScheme="brand.orange"
              onClick={() => handleSaveLayout()}
            >
              Save Custom Layout
            </Button>
            <Button
              variant="ghost"
              size="sm"
              colorScheme="gray"
              onClick={() => handleCancelCustomise()}
            >
              Cancel
            </Button>
          </HStack>
        </Flex>
        )}
        <Stack
          direction={{ base: 'column', md: 'row' }}
          mb={4}
          width="100%"
          justify="space-between"
          sx={{
            '@media print': {
              display: 'none',
            },
          }}
          className="hidden-print"
        >
          {!isCustomising && (
          <Heading as="h1">
            {pageTitle}
            <InfoScroller expandHelpSection={expandHelpSection} ref={helpSectionRef} />
          </Heading>
          )}
          <HStack>
            {(showDashboardSwitch || hasDeveloperRole) && !isCustomising && (
            <HStack
              py={1}
              pl={2}
              pr={2}
              border="1px solid"
              borderColor="gray.100"
              borderRadius="base"
            >
              <>
                {!isCustomView && (
                <HStack spacing={2}>
                  <Text fontSize="sm">Advanced view</Text>
                  <Switch
                    colorScheme="brand.orange"
                    size="sm"
                    onChange={(e) => setViewType(e.target.checked ? 'advanced' : 'basic')}
                    isChecked={viewType === 'advanced'}
                  />
                </HStack>
                )}
                {hasDeveloperRole && (
                <>
                  {isCustomView && (
                  <Button
                    leftIcon={<Icon as={FiRotateCw} boxSize={4} />}
                    variant="ghost"
                    size="sm"
                    colorScheme="gray"
                    onClick={() => handleResetLayout()}
                  >
                    Reset to Default
                  </Button>
                  )}
                  <Button
                    leftIcon={<Icon as={FiSliders} boxSize={4} />}
                    variant="ghost"
                    size="sm"
                    colorScheme="gray"
                    onClick={() => setIsCustomising(!isCustomising)}
                  >
                    Customise Layout
                  </Button>
                </>
                )}
              </>
            </HStack>
            )}
            {!isCustomising && (
              <PrintablePdf
                pageTitle={pageTitle}
                fileName={pageTitle}
                printableRef={printableRef}
                printableStyles={
                  `.pdf-container .grid {
                    display: grid;
                    width: auto;
                    grid-template-columns: repeat(6, 1fr);
                    gap: 2mm;
                    margin: 0px;
                  }
                  .pdf-container .indicator {
                    transform: scale(0.75);
                  }
                  .pdf-container .widget-container {
                    border: 1px solid #ddd;
                    border-radius: 4px;
                    background-color: #fff;
                    box-shadow: none;
                  }
                  .pdf-container .widget-header {
                    padding: 4px 6px 0px 6px;
                  }
                  .pdf-container .widget-header > p {
                    font-size: 10px;
                  }
                  .pdf-container .widget-body {
                    min-height: unset;
                    max-height: 100px;
                  }
                  .pdf-container .widget-description > p {
                    font-size: 8px;
                  }
                  .pdf-container .widget-footer {
                    padding: 3px 6px 6px 6px;
                  }
                  .pdf-container .performance-stats {
                    padding: 6px;
                    border: 1px solid #ddd;
                    border-radius: 4px;
                    background-color: #fff;
                    box-shadow: none;
                    width: auto;
                    margin: 0px;
                  }
                  .pdf-container .stat-label {
                    font-size: 10px;
                  }
                  .pdf-container .stat-value {
                    font-size: 16px;
                  }
                  `
                }
              />
            )}
          </HStack>
        </Stack>
        <StateHighwaysSubnetworkSelector mb={4} allowedGroups={['hno']} />
        <Box ref={printableRef}>
          {!isCustomising
          && (
          <SimpleGrid
            spacing={4}
            columns={{
              base: 1, sm: 2, md: 3, lg: 4, xl: 5, '2xl': 5, '3xl': 6, '4xl': 8,
            }}
            className="grid"
            sx={{
              '@media print': {
                gridTemplateColumns: 'repeat(6, minmax(64mm,1fr))!important',
                gap: '2mm',
              },
            }}
          >
            {allowedWidgetList.map((key) => {
              const WidgetComponent = widgetsMap[key];
              return (
                WidgetComponent ? (
                  <WidgetComponent key={key} />
                ) : null
              );
            })}
          </SimpleGrid>
          )}
          {isCustomising && (
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
            onDragCancel={handleDragCancel}
          >
            <SortableContext
              items={widgetList}
              strategy={rectSortingStrategy}
            >
              <SimpleGrid
                spacing={4}
                columns={{
                  base: 1, sm: 2, md: 3, lg: 4, xl: 5, '2xl': 5, '3xl': 6, '4xl': 8,
                }}
              >
                {widgetList.map((key) => {
                  const WidgetComponent = widgetsMap[key];
                  return (
                    WidgetComponent ? (
                      <SortableItem key={key} id={key}>
                        <Box height="100%" position="relative">
                          {isCustomising
                        && (
                        <IconButton
                          position="absolute"
                          top={2}
                          right={2}
                          size="sm"
                          variant="solid"
                          colorScheme="gray"
                          icon={<Icon as={FiEye} boxSize={4} />}
                          pointerEvents="auto"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleSetHidden(key);
                          }}
                          zIndex={200}
                        />
                        )}
                          <WidgetComponent key={key} overrideDisplayLogic />
                        </Box>
                      </SortableItem>
                    ) : null
                  );
                })}
                {hiddenReports.map((key) => {
                  const WidgetComponent = widgetsMap[key];
                  return (
                    WidgetComponent ? (
                      <SortableItem key={key} id={key} isCustomising={isCustomising} isHidden>
                        <Box position="relative" height="100%">
                          <IconButton
                            position="absolute"
                            top={2}
                            right={2}
                            size="sm"
                            variant="solid"
                            colorScheme="gray"
                            icon={<Icon as={FiEyeOff} boxSize={4} />}
                            onClick={() => handleSetVisible(key)}
                            zIndex={100}
                          />
                          <Box
                            position="absolute"
                            top={0}
                            left={0}
                            right={0}
                            bottom={0}
                            zIndex={50}
                            background="rgba(255, 255, 255, 0.8)"
                          />
                          <WidgetComponent key={key} overrideDisplayLogic />
                        </Box>
                      </SortableItem>
                    ) : null
                  );
                })}
              </SimpleGrid>
            </SortableContext>
            <DragOverlay>
              {activeId ? (
                <Box position="relative" height="100%" boxShadow="lg">
                  <IconButton
                    position="absolute"
                    top={2}
                    right={2}
                    size="sm"
                    variant="solid"
                    colorScheme="gray"
                    icon={<Icon as={FiEye} boxSize={4} />}
                    onClick={() => null}
                    zIndex={100}
                  />
                  <Box
                    position="absolute"
                    top={0}
                    left={0}
                    right={0}
                    bottom={0}
                    zIndex={100}
                    cursor="grabbing"
                  />
                  {createElement(widgetsMap[activeId], { key: activeId })}
                </Box>
              ) : null}
            </DragOverlay>
          </DndContext>
          )}
          {((!isCustomView && !isCustomising && !hideStatsWidget) || (isCustomView && customDashboardLayout?.showNetworkStats && !isCustomising && !hideStatsWidget)) && (
            <StatsWidget showNetworkCharacteristicButtons={viewType === 'advanced' || isCustomView} />
          )}
          {isCustomising && !hideStatsWidget && (
          <Box
            p={2}
            pt={0}
            mt={4}
            border="1px solid"
            borderColor="gray.100"
            borderRadius="md"
            position="relative"
          >
            <IconButton
              size="sm"
              variant="solid"
              colorScheme="gray"
              icon={<Icon as={tempShowNetworkStats ? FiEye : FiEyeOff} boxSize={4} />}
              onClick={() => setTempShowNetworkStats(!tempShowNetworkStats)}
              zIndex={100}
              position="absolute"
              right={2}
              top={2}
            />
            <Box opacity={tempShowNetworkStats === true ? 1 : 0.4}>
              <StatsWidget />
            </Box>
          </Box>
          )}
        </Box>
        {!isCustomising && (
        <>
          <InfoContentCollapse
            ref={helpSectionRef}
            title="Information Sources"
            html={null}
            index={index}
            setIndex={setIndex}
          >
            <SourcesTable />
          </InfoContentCollapse>
          <ConfirmDialog />
        </>
        )}
      </Container>
    </MainLayout>
  );
}
