import {
  Box, Container, Flex, Text, Wrap,
} from '@chakra-ui/react';
import MainLayout from '@src/components/layouts/main';
import ReportCard from '@src/modules/performance/components/ReportCard';
import {
  useLengthVsVKT,
  useRestrictedBridges,
  useRoadNetworkUseKPI,
  useVKT,
} from '@src/modules/performance/context/reports-api-hooks';
import { useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Pagination from '../../components/Pagination';
import InfoContentCollapse from './components/information/InfoContentCollapse';
import InfoScroller from './components/information/InfoScroller';
import SLLineChart from './components/selfloading/SLLineChart';
import SLStackedLineChart from './components/selfloading/SLStackedLineChart';
import SLTornadoChart from './components/selfloading/SLTornadoChart';
import html from './help/RoadNetworkUse.html';
import { CircleIndicator } from '../../components/Indicators';
import { roundKpiValue } from '../../shared/helpers';
import PrintablePdf from '../../components/PrintablePdf';

const subheader = 'Roads and Bridges';

const lineColours = [
  'blue',
  'orangered',
  'grey',
];

const stackedColours = ['transblue', 'transgrey'];

function RoadNetworkUse() {
  const { pathname } = useLocation();
  const reportUrl = pathname.split('/').pop();
  const { data: roadNetworkUseData, isLoadingKpi } = useRoadNetworkUseKPI();
  const vkt = useVKT();
  const restrictedBridges = useRestrictedBridges();
  const lengthVsVktOnrc = useLengthVsVKT('onrc');
  const lengthVsVktOnf = useLengthVsVKT('onf');
  const printableRef = useRef();

  // this controls the expansion of the help section
  const [index, setIndex] = useState(null);

  const helpSectionRef = useRef(null);
  // triggered when the user clicks on the help icon in the title
  const expandHelpSection = () => {
    setIndex(0);
  };

  return (
    <MainLayout backgroundColor="gray.50">
      <Container maxW="full">
        <Pagination
          currentReportUrl={reportUrl}
          pdfPrint={(
            <PrintablePdf
              fileName="Road Maintenance Delivery"
              printableRef={printableRef}
              printableStyles={null}
            />
          )}
        />
        <Box ref={printableRef}>
          <ReportCard mt={2} pt={0}>
            <ReportCard.Header
              title="Road Network Use"
              subtitle={subheader}
              info={<InfoScroller expandHelpSection={expandHelpSection} ref={helpSectionRef} />}
            >
              <Flex align="center">
                <Box mr={3}>
                  <CircleIndicator
                    size={60}
                    colour={roadNetworkUseData?.percentage !== undefined ? 'gray.700' : null}
                    value={roadNetworkUseData?.percentage !== undefined ? `${roundKpiValue(roadNetworkUseData?.percentage, 5, 1, 'max')}%` : undefined}
                    isLoading={isLoadingKpi || !roadNetworkUseData}
                  />
                </Box>
                <Text fontWeight="bold" color="gray">
                  {roundKpiValue(roadNetworkUseData?.value, 5, 1, 'max')}
                  {' '}
                  Million VKT
                  <br />
                  <Text as="span" fontSize="xs" color="gray">
                    (
                    {roadNetworkUseData?.averageYearRange}
                    {' '}
                    Annual Avg)
                  </Text>
                </Text>
              </Flex>
            </ReportCard.Header>
            <ReportCard.Body>
              <Wrap spacing={4}>
                <Box>
                  <Text mb={5} textStyle="reportSectionHeader" className="report-section-header">Roads and Bridges</Text>
                  <Flex alignContent="start" className="gap">
                    {/* two charts */}
                    <SLStackedLineChart
                      queryInfo={vkt}
                      title1="Vehicle kilometres travelled (VKT)"
                      title2="&nbsp;"
                      labels={['Urban', 'Rural']}
                      colours={stackedColours}
                      unitprefix=""
                      unitpostfix="M"
                      precision={0}
                    />
                    <SLLineChart
                      queryInfo={restrictedBridges}
                      title1="No. of restricted bridges"
                      title2="&nbsp;"
                      labels={['Speed', 'Weight']}
                      colours={lineColours}
                      unitprefix=""
                      unitpostfix=""
                      precision={0}
                    />
                  </Flex>
                </Box>

                <Box>
                  <Text mb={5} textStyle="reportSectionHeader" className="report-section-header">Journey Distribution</Text>
                  <Flex alignContent="start" className="gap">
                    <SLTornadoChart
                      queryInfo={lengthVsVktOnrc}
                      title1="Length vs VKT (ONRC)"
                      title2="&nbsp;"
                      labels={[
                        'Length %',
                        'Vehicle kilometres travelled %',
                      ]}
                      colours={['blue', 'red']}
                      unitprefix=""
                      unitpostfix="%"
                      precision={0}
                    />
                    <SLTornadoChart
                      queryInfo={lengthVsVktOnf}
                      title1="Length vs VKT (ONF)"
                      title2="&nbsp;"
                      labels={[
                        'Length %',
                        'Vehicle kilometres travelled %',
                      ]}
                      colours={['blue', 'red']}
                      unitprefix=""
                      unitpostfix="%"
                      precision={0}
                    />
                  </Flex>
                </Box>
              </Wrap>
            </ReportCard.Body>
          </ReportCard>
        </Box>
        <InfoContentCollapse
          ref={helpSectionRef}
          title="Information Sources"
          html={html}
          index={index}
          setIndex={setIndex}
        />
      </Container>
    </MainLayout>
  );
}

export default RoadNetworkUse;
