import {
  Box, Heading, Link, Stat, StatHelpText, StatLabel, StatNumber, SimpleGrid, HStack,
  Skeleton,
  SkeletonText,
} from '@chakra-ui/react';
import Card from '@src/modules/performance/components/Card';
import {
  isEmpty, isNumber, last, reduce,
} from 'lodash';
import { Link as ReactRouterLink } from 'react-router-dom';

import {
  useCyclewaysNetworkLength, useDeliveryKPI, useRoadMaintenanceKPI, useStats,
} from '@src/modules/performance/context/reports-api-hooks';
import LockYearBadge from '../LockYearBadge';

// NOTE:

// renders a list of network characteristics, which will vary by council
// some have only a few, some have lots
function StatsWidget({ showNetworkCharacteristicButtons, rest }) {
  const { data, isLoading: isLoadingStats, isError } = useStats();
  const { data: deliveryData, isLoading: isLoadingDelivery } = useDeliveryKPI();
  const { data: cyclewayData, isLoading: isLoadingCycleways } = useCyclewaysNetworkLength();
  const { data: roadMaintenanceData, isloading: isLoadingRoadMaintenance } = useRoadMaintenanceKPI();

  const isLoading = isLoadingStats || isLoadingDelivery || isLoadingCycleways || isLoadingRoadMaintenance;

  const formatNumber = (value, digits, prefix, postfix, noDataValue) => {
    if (value !== null && value !== '') {
      return `${prefix || ''}${value.toLocaleString('en-NZ', { minimumFractionDigits: digits, maximumFractionDigits: digits })}${postfix || ''}`;
    }
    if (noDataValue !== null) {
      return noDataValue;
    }
    return 'N/A';
  };

  return (
    <Box width="100%" mt={4} {...rest}>
      <HStack mb={4} width="100%" justify="space-between" align="center" className="hidden-print">
        <Heading as="h2" fontSize="xl">Network Statistics</Heading>
        {!isLoading && showNetworkCharacteristicButtons && (
          <HStack textAlign="center">
            <Link
              as={ReactRouterLink}
              to="/performance/network-characteristics"
              fontSize="xs"
              display="block"
              p={2}
              border="1px solid"
              borderColor="gray.100"
              borderRadius="md"
              textDecoration="none"
              _hover={{
                borderColor: 'blue.200',
              }}
            >
              ONRC Network Characteristics
            </Link>
            <Link
              as={ReactRouterLink}
              to="/onf/network-characteristics"
              fontSize="xs"
              display="block"
              p={2}
              border="1px solid"
              borderColor="gray.100"
              borderRadius="md"
              textDecoration="none"
              _hover={{
                borderColor: 'blue.200',
              }}
            >
              ONF Network Characteristics
            </Link>
          </HStack>
        )}
      </HStack>
      <Card
        className="performance-stats"
        sx={{
          '@media print': {
            padding: '8px',
            border: '1px solid #ddd',
            borderRadius: '8px',
            backgroundColor: '#fff',
            boxShadow: 'none',
          },
        }}
      >
        {(isLoading || isError) && (
        <SimpleGrid
          spacing={4}
          columns={{
            base: 5,
          }}
          width="100%"
        >
          {Array.from({ length: 10 }, (_, i) => (
            <Stat key={`kpi-${i}`}>
              <SkeletonText width="30%" noOfLines={1}><StatLabel className="stat-label" /></SkeletonText>
              <Skeleton width="40%" height="24px" my={2}><StatNumber className="stat-value" /></Skeleton>
              <SkeletonText width="10%" noOfLines={1}><StatHelpText /></SkeletonText>
            </Stat>
          ))}
        </SimpleGrid>
        )}
        {(!isLoading && !isError && data)
            && (

              <SimpleGrid
                spacing={4}
                columns={{
                  base: 2,
                  sm: 3,
                  md: 5,
                }}
                width="100%"
                sx={{
                  '@media print': {
                    gridTemplateColumns: 'repeat(5, minmax(20mm,1fr))!important',
                    gap: '2mm',
                  },
                }}
              >
                <Stat key="kpi-pop">
                  <StatLabel className="stat-label">Population</StatLabel>
                  <StatNumber className="stat-value">{formatNumber(data.Population.Value, 0, null, null, 0)}</StatNumber>
                  <StatHelpText>
                    <LockYearBadge
                      align="flex-start"
                      justify="flex-start"
                      lockYear={data.Population.LockYear}
                    />
                  </StatHelpText>
                </Stat>
                <Stat key="kpi-gdp">
                  <StatLabel className="stat-label">Maintenance ($/km)</StatLabel>
                  <StatNumber className="stat-value">{roadMaintenanceData?.costPerKm ? formatNumber(roadMaintenanceData?.costPerKm, 0, '$', null, 0) : 'No data'}</StatNumber>
                  <StatHelpText>
                    <LockYearBadge
                      align="flex-start"
                      justify="flex-start"
                      lockYear={roadMaintenanceData?.quadLockYear}
                    />
                  </StatHelpText>
                </Stat>
                <Stat key="kpi-budget">
                  <StatLabel className="stat-label">Budget ($M)</StatLabel>
                  <StatNumber className="stat-value">{isNumber(deliveryData?.budget) ? formatNumber(deliveryData?.budget, 1, '$', null, 'No data') : 'No data'}</StatNumber>
                  <StatHelpText>
                    <LockYearBadge
                      align="flex-start"
                      justify="flex-start"
                      lockYear={deliveryData?.lockYear}
                    />
                  </StatHelpText>
                </Stat>
                <Stat key="kpi-val">
                  <StatLabel className="stat-label">Valuation ($M)</StatLabel>
                  <StatNumber className="stat-value">{formatNumber(data.Valuation.Value, 0, '$', null, 0)}</StatNumber>
                  <StatHelpText>
                    <LockYearBadge
                      align="flex-start"
                      justify="flex-start"
                      lockYear={data.Valuation.ReportYear}
                    />
                  </StatHelpText>
                </Stat>
                <Stat key="kpi-exp">
                  <StatLabel className="stat-label">Expenditure ($M)</StatLabel>
                  <StatNumber className="stat-value">{formatNumber(data.Expenditure.Value, 1, '$', null, 0)}</StatNumber>
                  <StatHelpText>
                    <LockYearBadge
                      align="flex-start"
                      justify="flex-start"
                      lockYear={data.Expenditure.LockYear}
                    />
                  </StatHelpText>
                </Stat>
                <Stat key="kpi-cap">
                  <StatLabel className="stat-label">Expenditure per capita</StatLabel>
                  <StatNumber className="stat-value">{formatNumber(data.ExpenditurePerCapita.Value, 0, '$', null, 0)}</StatNumber>
                  <StatHelpText>
                    <LockYearBadge
                      align="flex-start"
                      justify="flex-start"
                      lockYear={data.ExpenditurePerCapita.LockYear}
                    />
                  </StatHelpText>
                </Stat>
                <Stat key="kpi-tot-rd">
                  <StatLabel className="stat-label">Total roads (km)</StatLabel>
                  <StatNumber className="stat-value">{formatNumber(data.TotalKm.Value, 0, null, null, 0)}</StatNumber>
                  <StatHelpText>
                    <LockYearBadge
                      align="flex-start"
                      justify="flex-start"
                      lockYear={data.TotalKm.LockYear}
                    />
                  </StatHelpText>
                </Stat>
                <Stat key="kpi-tot-cyc">
                  <StatLabel className="stat-label">Total cycleways (km)</StatLabel>
                  <StatNumber className="stat-value">{!isEmpty(cyclewayData?.values) ? reduce(cyclewayData?.values, (acc, v) => acc + last(v), 0).toFixed(1) : 'No data'}</StatNumber>
                  <StatHelpText>
                    <LockYearBadge
                      align="flex-start"
                      justify="flex-start"
                      lockYear={cyclewayData?.lockYear}
                    />
                  </StatHelpText>
                </Stat>
                <Stat key="kpi-brid">
                  <StatLabel className="stat-label">No. of bridges</StatLabel>
                  <StatNumber className="stat-value">{formatNumber(data.BridgesCount.Value, 0, null, null, 0)}</StatNumber>
                  <StatHelpText>
                    <LockYearBadge
                      align="flex-start"
                      justify="flex-start"
                      lockYear={data.BridgesCount.LockYear}
                    />
                  </StatHelpText>
                </Stat>
                <Stat key="kpi-far">
                  <StatLabel className="stat-label">FAR</StatLabel>
                  <StatNumber className="stat-value">{formatNumber(data.FAR.Value * 100.0, 0, null, '%', 0)}</StatNumber>
                  <StatHelpText>
                    <LockYearBadge
                      align="flex-start"
                      justify="flex-start"
                      lockYear={data.FAR.LockYear}
                    />
                  </StatHelpText>
                </Stat>
              </SimpleGrid>
            )}
      </Card>
    </Box>
  );
}

export default StatsWidget;
