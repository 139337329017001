import {
  Box, Container, Flex, Text, Wrap,
} from '@chakra-ui/react';
import MainLayout from '@src/components/layouts/main';
import ReportCard from '@src/modules/performance/components/ReportCard';
import {
  useFreightAccessibility,
  useJourneysUnplannedEvents,
  useNetworkAvailabilityKPI, useRoadAccessLost,
} from '@src/modules/performance/context/reports-api-hooks';
import { useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Pagination from '../../components/Pagination';
import InfoContentCollapse from './components/information/InfoContentCollapse';
import InfoScroller from './components/information/InfoScroller';
import SLTornadoChart from './components/selfloading/SLTornadoChart';
import html from './help/NetworkAvailability.html';
import { CircleIndicator } from '../../components/Indicators';
import { roundKpiValue } from '../../shared/helpers';
import PrintablePdf from '../../components/PrintablePdf';

const subheader = 'Resilience and Freight Accessibility';

const chartColours = ['lightblue', 'orange'];

function NetworkAvailability() {
  const { pathname } = useLocation();
  const reportUrl = pathname.split('/').pop();
  const { data: networkAvailabilityData, isLoadingKpi } = useNetworkAvailabilityKPI();
  const journeysUnplannedEventsOnrc = useJourneysUnplannedEvents('onrc');
  const roadAccessLostOnrc = useRoadAccessLost('onrc');
  const freightAccessibilityOnrc = useFreightAccessibility('onrc');
  const journeysUnplannedEventsOnf = useJourneysUnplannedEvents('onf');
  const roadAccessLostOnf = useRoadAccessLost('onf');
  const freightAccessibilityOnf = useFreightAccessibility('onf');
  const printableRef = useRef();
  // this controls the expansion of the help section
  const [index, setIndex] = useState(null);

  const helpSectionRef = useRef(null);
  // triggered when the user clicks on the help icon in the title
  const expandHelpSection = () => {
    setIndex(0);
  };

  return (
    <MainLayout backgroundColor="gray.50">
      <Container maxW="full">
        <Pagination
          currentReportUrl={reportUrl}
          pdfPrint={(
            <PrintablePdf
              fileName="Network Availability and Accessibility"
              printableRef={printableRef}
              printableStyles={null}
            />
          )}
        />
        <Box ref={printableRef}>
          <ReportCard mt={2} pt={0}>
            <ReportCard.Header
              title="Network Availability and Accessibility"
              subtitle={subheader}
              info={<InfoScroller expandHelpSection={expandHelpSection} ref={helpSectionRef} />}
            >
              <Flex align="center">
                <Box mr={3}>
                  <CircleIndicator
                    size={60}
                    colour={!networkAvailabilityData?.nodata ? 'gray.700' : null}
                    value={!networkAvailabilityData?.nodata ? `${roundKpiValue(networkAvailabilityData?.value, 99.5, 1, 'min')}%` : undefined}
                    isLoading={isLoadingKpi || !networkAvailabilityData}
                  />
                </Box>
                <Text fontWeight="bold" color="gray">
                  Proportion of network accessible to
                  <br />
                  Class 1 and 50MAX vehicles
                </Text>
              </Flex>
            </ReportCard.Header>
            <ReportCard.Body>
              <Wrap spacing={4}>
                <Box>
                  <Flex>
                    <Box>
                      <Text mb={5} textStyle="reportSectionHeader" className="report-section-header">Resilience Distributions</Text>
                      <Flex alignContent="start" className="gap">
                        <SLTornadoChart
                          queryInfo={journeysUnplannedEventsOnrc}
                          title1="No. incidents vs No. of journeys impacted (ONRC)"
                          labels={[
                            'Incidents',
                            'Journeys Impacted',
                          ]}
                          colours={chartColours}
                          unitprefix=""
                          unitpostfix=""
                          precision={0}
                        />
                      </Flex>
                      <Flex alignContent="start" className="gap">
                        <SLTornadoChart
                          queryInfo={journeysUnplannedEventsOnf}
                          title1="No. incidents vs No. of journeys impacted (ONF)"
                          labels={[
                            'Incidents',
                            'Journeys Impacted',
                          ]}
                          colours={chartColours}
                          unitprefix=""
                          unitpostfix=""
                          precision={0}
                        />
                      </Flex>
                    </Box>
                    <Box>
                      <Text mb={5} textStyle="reportSectionHeader" className="report-section-header">&nbsp;</Text>
                      <Flex alignContent="start" className="gap">
                        <SLTornadoChart
                          queryInfo={roadAccessLostOnrc}
                          title1="No. road closures vs no. vehicles affected (ONRC)"
                          labels={[
                            'Road Closures',
                            'Vehicles Affected Annually',
                          ]}
                          colours={chartColours}
                          unitprefix=""
                          unitpostfix=""
                          precision={0}
                        />
                      </Flex>
                      <Flex alignContent="start" className="gap">
                        <SLTornadoChart
                          queryInfo={roadAccessLostOnf}
                          title1="No. road closures vs no. vehicles affected (ONF)"
                          labels={[
                            'Road Closures',
                            'Vehicles Affected Annually',
                          ]}
                          colours={chartColours}
                          unitprefix=""
                          unitpostfix=""
                          precision={0}
                        />
                      </Flex>
                    </Box>
                  </Flex>
                  <Text fontSize="10px">Source: Road Efficiency Group Te Ringa Maimoa Transport Insights</Text>
                </Box>
                <Box>
                  <Text mb={5} textStyle="reportSectionHeader" className="report-section-header">Freight Accessibility Distribution</Text>
                  <Flex alignContent="start" className="gap">
                    <SLTornadoChart
                      queryInfo={freightAccessibilityOnrc}
                      title1="Network not available to Class 1 HCV vs 50MAX HPMV (ONRC)"
                      labels={[
                        'Class 1 HCV %',
                        '50 Max %',
                      ]}
                      colours={chartColours}
                      unitprefix=""
                      unitpostfix="%"
                      precision={1}
                    />
                  </Flex>
                  <Flex alignContent="start" className="gap">
                    <SLTornadoChart
                      queryInfo={freightAccessibilityOnf}
                      title1="Network not available to Class 1 HCV vs 50MAX HPMV (ONF)"
                      labels={[
                        'Class 1 HCV %',
                        '50 Max %',
                      ]}
                      colours={chartColours}
                      unitprefix=""
                      unitpostfix="%"
                      precision={1}
                    />
                  </Flex>
                  <Text fontSize="10px">
                    Source: Road Efficiency Group Te Ringa Maimoa Transport Insights
                  </Text>
                </Box>
              </Wrap>
            </ReportCard.Body>
          </ReportCard>
        </Box>
        <InfoContentCollapse
          ref={helpSectionRef}
          title="Information Sources"
          html={html}
          index={index}
          setIndex={setIndex}
        />
      </Container>
    </MainLayout>
  );
}

export default NetworkAvailability;
