import { TrafficLightColours as COLOURS, servicePerformanceKpiLegend } from '@src/modules/performance/shared/constants';
import { Text } from '@chakra-ui/react';
import Widget from './Widget';
import { useServicePerformanceKPI } from '../../context/reports-api-hooks';
import { CircleIndicator } from '../Indicators';
import IndicatorLegend from '../IndicatorLegend';
import { mapValueToColourRange, roundKpiValue } from '../../shared/helpers';

export default function ServicePerformanceWidget() {
  const { data: servicePerformanceData, isLoading, isError } = useServicePerformanceKPI();

  const title = 'Service Performance';
  const description = 'Non-financial performance\nmeasure achievement';
  const colour = mapValueToColourRange(servicePerformanceData?.percentageTargetAchieved, 'servicePerformanceKpi');

  // If there is an error and no data we want to hide the widget
  if (isError && !servicePerformanceData) return null;

  return (
    <Widget
      link="/performance/dashboard/service-performance"
      title={title}
      description={description}
      lockYear={servicePerformanceData?.[0]?.lockYear}
      quarter={null}
      isLoading={isLoading && !servicePerformanceData}
      moreInfoContent={(
        <>
          <Text fontSize="sm" mb={4}>
            The proportion of targets achieved for the Roads and Footpaths non-financial performance measures in the current 3 year period
          </Text>
          <IndicatorLegend items={servicePerformanceKpiLegend} />
        </>
      )}
    >
      <CircleIndicator
        size={100}
        colour={COLOURS[colour]}
        value={servicePerformanceData?.percentageTargetAchieved !== null && servicePerformanceData?.percentageTargetAchieved !== undefined ? `${roundKpiValue(servicePerformanceData?.percentageTargetAchieved, 5, 1)}%` : null}
        isLoading={isLoading}
        padding={0}
      />
    </Widget>
  );
}
