import { Text } from '@chakra-ui/react';
import { useIsUserInRole } from '@transport-insights/uikit';
import Widget from './Widget';
import { CircleIndicator } from '../Indicators';
import { useTemporaryTrafficManagementKpi } from '../../context/temporary-traffic-management-api-hooks';
import { roundKpiValue } from '../../shared/helpers';

export default function TemporaryTrafficManagementWidget() {
  const { data: ttmData, isLoading, isError } = useTemporaryTrafficManagementKpi();
  const hasDeveloperRole = useIsUserInRole('Developer');

  const title = 'Temporary Traffic\nManagement';
  const description = 'Proportion of maintenance\nexpenditure for the quarter (%)';

  // If there is an error and no data we want to hide the widget
  if (isError && (!ttmData || typeof ttmData.value !== 'number')) return null;

  return (
    <Widget
      link={hasDeveloperRole ? '/performance/dashboard/temporary-traffic-management' : null}
      title={title}
      description={description}
      lockYear={ttmData?.quadLockYear}
      quarter={ttmData?.quarter}
      isLoading={isLoading && !ttmData}
      moreInfoContent={
        <Text fontSize="sm">The proportion of Temporary Traffic Management expenditure compared to all maintenance expenditure for the quarter.</Text>
      }
    >
      <CircleIndicator
        size={100}
        colour={ttmData?.value !== undefined ? 'gray.700' : null}
        value={ttmData?.value !== undefined ? `${roundKpiValue(ttmData?.value, 7, 1)}%` : undefined}
        isLoading={isLoading || !ttmData}
      />
    </Widget>
  );
}
