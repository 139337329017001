import {
  Box, Container, Flex,
  Text,
  Table, Thead, Tbody, Tr, Th, Td,
  Spinner,
  Wrap,
  TableCaption,
  Center,
} from '@chakra-ui/react';
import MainLayout from '@src/components/layouts/main';
import Chart from 'react-apexcharts';
import { currentDatabaseSelector } from '@transport-insights/uikit';
import { SplitCircleColours as COLOURS, e3Legend } from '@src/modules/performance/shared/constants';
import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import Pagination from '../../components/Pagination';
import ReportCard from '../../components/ReportCard';
import InfoContentCollapse from './components/information/InfoContentCollapse';
import InfoScroller from './components/information/InfoScroller';
import { ChartColours } from './components/charts/ChartColours';
import {
  useE3Kpi,
  useE3PeerGroups,
  useE3HistoricalData,
} from '../../context/reports-api-hooks';
import IndicatorLegend from '../../components/IndicatorLegend';
import { SplitCircleIndicator } from '../../components/Indicators';
import LockYearBadge from '../../components/LockYearBadge';

const subheader = 'Ranking within Peer Group';
const colourMap = {
  1: COLOURS.LIGHT_BLUE,
  2: COLOURS.BLUE,
  3: COLOURS.DARK_BLUE,
  default: 'GREY',
};

const labelMap = {
  economy: 'Value for Money',
  effectiveness: 'Effectiveness',
  efficiency: 'Efficiency',
};

function E3() {
  const { pathname } = useLocation();
  const reportUrl = pathname.split('/').pop();
  const currentDatabase = useRecoilValue(currentDatabaseSelector);
  const {
    data: e3KpiData, isLoading: isLoadingKpi,
  } = useE3Kpi();
  const { data: historicalData, isLoading: isLoadingHistoricalData } = useE3HistoricalData();
  const { data: peerGroupData, isLoading: isLoadingPeerGroup } = useE3PeerGroups();

  const isLoading = isLoadingKpi || isLoadingHistoricalData || isLoadingPeerGroup;

  const sortedHistoricalData = historicalData?.sort((a, b) => a.year - b.year);
  const years = [...new Set(sortedHistoricalData?.map((x) => x.year).flat())];

  const historicalLockYear = sortedHistoricalData?.at(-1)?.lockYear;
  const peerGroupLockYear = peerGroupData?.at(-1)?.lockYear;

  // Sort peer group data by economy, efficiency, effectiveness, then alphabetically by roadCouncil
  const orderedPeerGroupData = peerGroupData?.sort((a, b) => {
    // Sort by economy (descending: 3 is best, 1 is lowest)
    if (a.data.economy !== b.data.economy) {
      return b.data.economy - a.data.economy;
    }
    // Sort by efficiency if economy is the same
    if (a.data.efficiency !== b.data.efficiency) {
      return b.data.efficiency - a.data.efficiency;
    }
    // Sort by effectiveness if economy and efficiency are the same
    if (a.data.effectiveness !== b.data.effectiveness) {
      return b.data.effectiveness - a.data.effectiveness;
    }
    // Sort alphabetically by roadCouncil if all other criteria are the same
    return (a.roadCouncil || '').localeCompare(b.roadCouncil || '');
  });

  const [colours, setColours] = useState([]);
  const [labels, setLabels] = useState([]);

  useEffect(() => {
    if (e3KpiData?.data) {
      setColours(Object.values(e3KpiData.data).map((x) => colourMap[x] || colourMap.default));
      setLabels(Object.keys(e3KpiData.data || []).map((x) => labelMap[x]));
    }
  }, [e3KpiData?.data]);

  // this controls the expansion of the help section
  const [index, setIndex] = useState(null);
  const helpSectionRef = useRef(null);
  const expandHelpSection = () => {
    setIndex(0);
  };

  return (
    <MainLayout backgroundColor="gray.50">
      <Container maxW="full">
        <Pagination currentReportUrl={reportUrl} />
        <ReportCard mt={2} pt={0}>
          <ReportCard.Header
            title="Value for Money, Efficiency and Effectiveness"
            subtitle={subheader}
            info={<InfoScroller expandHelpSection={expandHelpSection} ref={helpSectionRef} />}
          >
            <Flex align="center" pr={4}>
              <SplitCircleIndicator
                size={70}
                colours={colours}
                labels={labels}
                isLoading={isLoading && !e3KpiData}
              />
            </Flex>
          </ReportCard.Header>
          <ReportCard.Body>
            {(isLoading || !historicalData) && <Flex justify="center" align="center" h="300px"><Spinner color="brand.orange.500" size="lg" /></Flex>}
            {!isLoading && historicalData && (
              <Wrap spacing={6}>
                <Box>
                  <Flex align="center" justify="space-between" mb={5}>
                    <Text textStyle="reportSectionHeader" className="report-section-header">Quality</Text>
                    <LockYearBadge lockYear={historicalLockYear} />
                  </Flex>
                  <Chart
                    options={
                        {
                          title: {
                            text: '75% Roughness',
                          },
                          chart: {
                            animations: {
                              enabled: false,
                            },
                          },
                          xaxis: {
                            categories: years || [],
                          },
                          yaxis: [
                            {
                              forceNiceScale: true,
                              title: {
                                text: 'NAASRA counts/km',
                              },
                              labels: {
                                formatter(val) {
                                  return `${val.toFixed(0)}`;
                                },
                                offsetX: 20,
                              },
                            },
                          ],
                          grid: {
                            padding: {
                              left: 50,
                            },
                          },
                          dataLabels: {
                            enabled: false,
                          },
                          stroke: {
                            curve: 'straight',
                            width: [2, 2, 0],
                          },
                          colors: [ChartColours.orange, ChartColours.black],
                          fill: {
                            colors: [ChartColours.orange, ChartColours.black],
                          },
                          markers: {
                            size: [4, 4],
                            hover: {
                              size: 0,
                            },
                          },
                          legend: {
                            show: true,
                          },
                        }
                    }
                    series={
                        [
                          {
                            name: currentDatabase?.name,
                            type: 'line',
                            data: historicalData?.map((x) => x.quality) || [],
                          },
                          {
                            name: 'Peer Group',
                            type: 'line',
                            data: historicalData?.map((x) => x.peerGroupQuality) || [],
                          },
                        ]
                      }
                    type="line"
                    width="100%"
                    height="400"
                  />
                </Box>
                <Box>
                  <Flex align="center" justify="space-between" mb={5}>
                    <Text textStyle="reportSectionHeader" className="report-section-header">Cost</Text>
                    <LockYearBadge lockYear={historicalLockYear} />
                  </Flex>
                  <Chart
                    options={
                        {
                          title: {
                            text: 'Maintenance Cost / km',
                          },
                          chart: {
                            animations: {
                              enabled: false,
                            },
                          },
                          xaxis: {
                            categories: years || [],
                          },
                          yaxis: [
                            {
                              forceNiceScale: true,
                              title: {
                                text: '$1,000 / km',
                              },
                              labels: {
                                formatter(val) {
                                  return `$${val.toFixed(1)}`;
                                },
                                offsetX: 20,
                              },
                            },
                          ],
                          grid: {
                            padding: {
                              left: 50,
                            },
                          },
                          dataLabels: {
                            enabled: false,
                          },
                          stroke: {
                            curve: 'straight',
                            width: [2, 2, 0],
                          },
                          colors: [ChartColours.orange, ChartColours.black],
                          fill: {
                            colors: [ChartColours.orange, ChartColours.black],
                          },
                          markers: {
                            size: [4, 4],
                            hover: {
                              size: 0,
                            },
                          },
                          legend: {
                            show: true,
                          },
                        }
                    }
                    series={
                        [
                          {
                            name: currentDatabase?.name,
                            type: 'line',
                            data: historicalData?.map((x) => x.cost) || [],
                          },
                          {
                            name: 'Peer Group',
                            type: 'line',
                            data: historicalData?.map((x) => x.peerGroupCost) || [],
                          },
                        ]
                      }
                    type="line"
                    width="100%"
                    height="400"
                  />
                </Box>
                <Box>
                  <Flex align="center" justify="space-between" mb={5}>
                    <Text textStyle="reportSectionHeader" className="report-section-header">Quantity</Text>
                    <LockYearBadge lockYear={historicalLockYear} />
                  </Flex>
                  <Chart
                    options={
                        {
                          title: {
                            text: 'Length of work done (roads and drainage)',
                          },
                          chart: {
                            animations: {
                              enabled: false,
                            },
                          },
                          xaxis: {
                            categories: years || [],
                          },
                          yaxis: [
                            {
                              forceNiceScale: true,
                              title: {
                                text: 'Percentage of total network length',
                              },
                              labels: {
                                formatter(val) {
                                  return `${val.toFixed(1)}%`;
                                },
                                offsetX: 20,
                              },
                            },
                          ],
                          grid: {
                            padding: {
                              left: 50,
                            },
                          },
                          dataLabels: {
                            enabled: false,
                          },
                          stroke: {
                            curve: 'straight',
                            width: [2, 2, 0],
                          },
                          colors: [ChartColours.orange, ChartColours.black],
                          fill: {
                            colors: [ChartColours.orange, ChartColours.black],
                          },
                          markers: {
                            size: [4, 4],
                            hover: {
                              size: 0,
                            },
                          },
                          legend: {
                            show: true,
                          },
                        }
                    }
                    series={
                        [
                          {
                            name: currentDatabase?.name,
                            type: 'line',
                            data: historicalData?.map((x) => x.quantity) || [],
                          },
                          {
                            name: 'Peer Group',
                            type: 'line',
                            data: historicalData?.map((x) => x.peerGroupQuantity) || [],
                          },
                        ]
                      }
                    type="line"
                    width="100%"
                    height="400"
                  />
                </Box>
                <Box>
                  <Flex align="center" justify="space-between" mb={5}>
                    <Text textStyle="reportSectionHeader" className="report-section-header">Peer Group Members</Text>
                    <LockYearBadge lockYear={peerGroupLockYear} />
                  </Flex>
                  <Table
                    variant="simple"
                    width="100%"
                    sx={{
                      'td:nth-child(1)': {
                        borderRight: 'solid 2px lightgray',
                      },
                    }}
                    size="sm"
                  >
                    <Thead>
                      <Tr>
                        <Th textAlign="right">
                          Peers
                        </Th>
                        <Th textAlign="center">
                          {labelMap.economy}
                        </Th>
                        <Th textAlign="center">
                          {labelMap.efficiency}
                        </Th>
                        <Th textAlign="center">
                          {labelMap.effectiveness}
                        </Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {orderedPeerGroupData?.map((item) => (
                        <Tr key={`row-${item.roadCouncil}`} backgroundColor={currentDatabase.id === item.rcaId ? 'gray.50' : null}>
                          <Td border="none">
                            <Text
                              align="right"
                              color={currentDatabase.id === item.rcaId ? 'orange.500' : null}
                              fontWeight={currentDatabase.id === item.rcaId ? 'bold' : 'normal'}
                            >
                              {item.roadCouncil}
                            </Text>
                          </Td>
                          <Td border="none">
                            <Center>
                              <Box borderRadius="full" boxSize={8} backgroundColor={colourMap[item.data.economy]} />
                            </Center>
                          </Td>
                          <Td border="none">
                            <Center>
                              <Box borderRadius="full" boxSize={8} backgroundColor={colourMap[item.data.efficiency]} />
                            </Center>
                          </Td>
                          <Td border="none">
                            <Center>
                              <Box borderRadius="full" boxSize={8} backgroundColor={colourMap[item.data.effectiveness]} />
                            </Center>
                          </Td>
                        </Tr>
                      ))}
                    </Tbody>
                    <TableCaption textAlign="center">
                      <IndicatorLegend items={e3Legend} direction="column" />
                    </TableCaption>
                  </Table>
                </Box>
              </Wrap>
            )}
          </ReportCard.Body>
        </ReportCard>
        <InfoContentCollapse
          ref={helpSectionRef}
          title="Information Sources"
          html={null}
          index={index}
          setIndex={setIndex}
        >
          <Box background="white" p={4} borderRadius={4}>
            <Table variant="simple" size="sm">
              <Thead>
                <Tr>
                  <Th width="25%">REPORT ELEMENT</Th>
                  <Th>NOTES</Th>
                  <Th width="20%">SOURCE</Th>
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  <Td colSpan={3}>
                    <Text fontWeight="bold" fontSize="md" color="brand.orange.500">State Highway Pothole Repairs</Text>
                  </Td>
                </Tr>
                <Tr>
                  <Td colSpan={3}>
                    <Text fontWeight="bold" fontSize="md" color="brand.orange.500">Targets for road categories</Text>
                  </Td>
                </Tr>
                <Tr verticalAlign="top">
                  <Td fontWeight="bold">
                    % completed within 24 hours for high-volume, national and arterial roads
                  </Td>
                  <Td>
                    <Text mb={4}>
                      The colour grade for the percentage of potholes on high-volume, national, and arterial state highways that were repaired within 24 hours for the quarter:
                    </Text>
                    <IndicatorLegend items={e3Legend} />
                  </Td>
                  <Td>Sourced from NZTA</Td>
                </Tr>
                <Tr>
                  <Td colSpan={3}>
                    <Text fontWeight="bold" fontSize="md" color="brand.orange.500">Network Outcome Contracts</Text>
                  </Td>
                </Tr>
                <Tr verticalAlign="top">
                  <Td fontWeight="bold">% completed within 24 hours</Td>
                  <Td>
                    <Text>
                      The quarterly percentages of potholes on state highways within each Network Outcome Contract that were repaired within 24 hours.
                    </Text>
                  </Td>
                  <Td>Sourced from NZTA</Td>
                </Tr>
                <Tr>
                  <Td colSpan={3}>
                    <Text fontWeight="bold" fontSize="md" color="brand.orange.500">Regions</Text>
                  </Td>
                </Tr>
                <Tr verticalAlign="top">
                  <Td fontWeight="bold">% completed within 24 hours</Td>
                  <Td>
                    <Text fontSize="sm">
                      The quarterly percentages of potholes on state highways within each Region that were repaired within 24 hours.
                    </Text>
                  </Td>
                  <Td>Sourced from NZTA</Td>
                </Tr>
              </Tbody>
            </Table>
          </Box>
        </InfoContentCollapse>
      </Container>
    </MainLayout>
  );
}

export default E3;
