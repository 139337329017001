import {
  Box, Container, Flex, HStack, Text, Wrap,
} from '@chakra-ui/react';
import MainLayout from '@src/components/layouts/main';
import ReportCard from '@src/modules/performance/components/ReportCard';
import {
  useGDPByIndustry,
  useResidentPopulation,
  useTerritorialActivityKPI,
} from '@src/modules/performance/context/reports-api-hooks';
import {
  createRef,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useLocation } from 'react-router-dom';
import Pagination from '../../components/Pagination';
import InfoContentCollapse from './components/information/InfoContentCollapse';
import InfoScroller from './components/information/InfoScroller';
import InteractiveLegend from './components/information/InteractiveLegend';
import SLDualAxisBarLineChart from './components/selfloading/SLDualAxisBarLineChart';
import SLLineChart from './components/selfloading/SLLineChart';
import html from './help/TerritorialActivity.html';
import { roundKpiValue } from '../../shared/helpers';
import { CircleIndicator } from '../../components/Indicators';
import PrintablePdf from '../../components/PrintablePdf';

const subheader = 'Economic Activity';

const chartColours = ['lightblue', 'darkblue'];
const lineColours = [
  'darkblue',
  'blue',
  'orangered',
  'seagreen',
  'magenta',
];

function TerritorialActivity() {
  const { pathname } = useLocation();
  const reportUrl = pathname.split('/').pop();
  const { data: territorialActivityData, isLoadingKpi } = useTerritorialActivityKPI();
  const residentPopulation = useResidentPopulation();
  const gdpByIndustry = useGDPByIndustry();
  const [legendLabels, setLegendLabels] = useState([]);
  const chartRef = createRef();
  const printableRef = useRef();

  useEffect(() => {
    if (gdpByIndustry?.data) {
      setLegendLabels(gdpByIndustry.data.categories);
    }
  }, [gdpByIndustry]);

  // this controls the expansion of the help section
  const [index, setIndex] = useState(null);

  const helpSectionRef = useRef(null);
  // triggered when the user clicks on the help icon in the title
  const expandHelpSection = () => {
    setIndex(0);
  };

  return (
    <MainLayout backgroundColor="gray.50">
      <Container maxW="full">
        <Pagination
          currentReportUrl={reportUrl}
          pdfPrint={(
            <PrintablePdf
              fileName="Territorial Activity"
              printableRef={printableRef}
              printableStyles={null}
            />
          )}
        />
        <Box ref={printableRef}>
          <ReportCard mt={2} pt={0}>
            <ReportCard.Header
              title="Territorial Activity"
              subtitle={subheader}
              info={<InfoScroller expandHelpSection={expandHelpSection} ref={helpSectionRef} />}
            >
              <Flex align="center">
                <HStack mr={3} spacing={0}>
                  <CircleIndicator
                    size={60}
                    colour={territorialActivityData?.percentage !== undefined ? 'gray.700' : null}
                    value={territorialActivityData?.percentage !== undefined ? `${roundKpiValue(territorialActivityData?.percentage, 5, 1, 'max')}%` : undefined}
                    isLoading={isLoadingKpi || !territorialActivityData}
                  />
                  <Text>
                    (
                    {territorialActivityData?.value}
                    )
                  </Text>
                </HStack>
                <Text fontWeight="bold" color="gray">
                  Population Growth
                  <br />
                  <Text as="span" fontSize="xs" color="gray">
                    (
                    {territorialActivityData?.averageYearRange}
                    {' '}
                    Annual Avg)
                  </Text>
                </Text>
              </Flex>
            </ReportCard.Header>
            <ReportCard.Body>
              <Wrap spacing={4}>
                <Box>
                  <Text mb={5} textStyle="reportSectionHeader" className="report-section-header">Population</Text>
                  <Flex alignContent="start" className="gap">
                    {/* one chart */}
                    <SLDualAxisBarLineChart
                      queryInfo={residentPopulation}
                      title1="Resident Population"
                      title2="&nbsp;"
                      labels={[
                        'Annual Change',
                        'Population',
                      ]}
                      vertlabels={[
                        'Annual Change',
                        'Population',
                      ]}
                      chartType={['bar', 'line']}
                      colours={chartColours}
                      unitprefix={['', '']}
                      unitpostfix={['', '']}
                      precision={1}
                      width={400}
                    />
                  </Flex>
                </Box>
                <Box>
                  <Text mb={5} textStyle="reportSectionHeader" className="report-section-header">Economic</Text>
                  <Flex alignContent="start" className="gap">
                    {/* one chart */}
                    <SLLineChart
                      queryInfo={gdpByIndustry}
                      title1="GDP by industry"
                      title2="Annual estimates and latest year provisional"
                      labels={[]}
                      colours={lineColours}
                      unitprefix="$"
                      unitpostfix="M"
                      hideLegend
                      width={400}
                      height={265}
                      ref={chartRef}
                    />
                    {/* legend */}
                    <Box mt="67px" ml={5}>
                      <InteractiveLegend
                        colours={lineColours}
                        values={legendLabels}
                        vertical
                        chartColours
                        title="Top 5 TA Industries"
                        chartRef={chartRef}
                      />
                    </Box>
                  </Flex>
                </Box>
              </Wrap>
            </ReportCard.Body>
          </ReportCard>
        </Box>
        <InfoContentCollapse
          ref={helpSectionRef}
          title="Information Sources"
          html={html}
          index={index}
          setIndex={setIndex}
        />
      </Container>
    </MainLayout>
  );
}

export default TerritorialActivity;
