/* eslint-disable react/display-name */
import { Icon } from '@chakra-ui/react';
import { FiInfo } from 'react-icons/fi';
import { forwardRef } from 'react';

// a help icon that when clicked scrolls the page to an auto expanded help section
const InfoScroller = forwardRef(({ expandHelpSection }, ref) => {
  const doClick = () => {
    if (expandHelpSection) {
      expandHelpSection();
    }

    // added a delay here to allow the help section to expand.
    // if we don't do this, then this will just scroll down the page to just above
    // the help section and the user still can't see it.
    setTimeout(() => {
      if (ref && ref.current) {
        // scroll to the link
        ref.current.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }
    }, 400);
  };

  return (
    <Icon
      as={FiInfo}
      onClick={doClick}
      boxSize={5}
      ml={2}
      color="brand.blue.600"
      verticalAlign="baseline"
      cursor="pointer"
      className="hidden-print"
    />
  );
});

export default InfoScroller;
