import { Text } from '@chakra-ui/react';
import Widget from './Widget';
import { useNetworkCharacteristicsKPI } from '../../context/reports-api-hooks';
import { CircleIndicator } from '../Indicators';
import { roundKpiValue } from '../../shared/helpers';

export default function NetworkPhysicalCharacteristicsWidget({ overrideDisplayLogic = false }) {
  const { data: networkPhysicalCharacteristicsData, isLoading, isError } = useNetworkCharacteristicsKPI();

  const title = 'Network Physical\nCharacteristics';
  const description = 'Change in road network\nlength over 5 years';

  // If there is an error or no data we want to hide the widget
  if (!overrideDisplayLogic && (isError || !networkPhysicalCharacteristicsData || networkPhysicalCharacteristicsData.nodata)) return null;

  return (
    <Widget
      link="/performance/dashboard/network-physical-characteristics"
      title={title}
      description={description}
      lockYear={networkPhysicalCharacteristicsData?.lockYear}
      quarter={null}
      isLoading={isLoading && !networkPhysicalCharacteristicsData}
      moreInfoContent={
        <Text fontSize="sm">The average change in road network length per year for the last five years.</Text>
      }
    >
      <CircleIndicator
        size={100}
        colour={networkPhysicalCharacteristicsData?.percentage !== undefined ? 'gray.700' : null}
        value={networkPhysicalCharacteristicsData?.percentage !== undefined ? `${roundKpiValue(networkPhysicalCharacteristicsData?.percentage, 5, 1, 'max')}%` : undefined}
        isLoading={isLoading || !networkPhysicalCharacteristicsData}
      />
    </Widget>
  );
}
