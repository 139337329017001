import { useEffect, useState } from 'react';
import { Box, Center, Text } from '@chakra-ui/react';
import LockYearBadge from '@src/modules/performance/components/LockYearBadge';
import TrafficLight from '../indicators/TrafficLight';

function TitledTrafficLight({
  title, text, queryInfo, precision,
}) {
  const [colour, setColour] = useState('GREY');
  const [value, setValue] = useState('-');
  const [max, setMax] = useState('-');

  useEffect(() => {
    if (queryInfo?.data) {
      const { data } = queryInfo;
      setColour(data.colour);
      if (precision !== null) {
        setValue(data.value.toFixed(precision));
        setMax(data?.max?.toFixed(precision));
      } else {
        setValue(data?.value);
        setMax(data?.max);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryInfo]);

  if (!queryInfo?.data) {
    return null;
  }

  return (
    <Box width={200} height={220}>
      <Box height={12}>
        <Text
          align="center"
          fontSize={12}
          fontWeight="bold"
          className="chart-title"
        >
          {title}
        </Text>
      </Box>
      <LockYearBadge lockYear={queryInfo?.data?.lockYear} />
      <Center>
        <TrafficLight size={20} colour={colour} />
      </Center>
      <Box mt={7}>
        <Text align="center">{`${value}${text}${max}`}</Text>
      </Box>
    </Box>
  );
}

export default TitledTrafficLight;
