import {
  Box, Flex, Text,
} from '@chakra-ui/react';

function ChartSvg({
  colours, size, gapColor = 'white', gapWidth = 2,
}) {
  const radius = size / 2;

  // Calculate the angles for the segments
  const segments = colours.length;
  const segmentAngle = 360 / segments;

  // Adjust rotation offset for clockwise adjustment
  const rotationOffset = -150;

  // Generate SVG paths for each segment and gap lines
  const paths = colours.map((colour, index) => {
    const startAngle = segmentAngle * index + rotationOffset;
    const endAngle = segmentAngle * (index + 1) + rotationOffset;

    // Convert angles to radians for calculations
    const startRad = (startAngle * Math.PI) / 180;
    const endRad = (endAngle * Math.PI) / 180;

    // Calculate start and end points on the circle
    const startX = radius + radius * Math.cos(startRad);
    const startY = radius + radius * Math.sin(startRad);
    const endX = radius + radius * Math.cos(endRad);
    const endY = radius + radius * Math.sin(endRad);

    // Determine if the path is a large arc (greater than 180°)
    const largeArcFlag = endAngle - startAngle > 180 ? 1 : 0;

    // Create the path description
    const pathData = `
      M ${radius},${radius} 
      L ${startX},${startY} 
      A ${radius},${radius} 0 ${largeArcFlag} 1 ${endX},${endY} 
      Z
    `;

    return (
      <path key={index} d={pathData} fill={colour} />
    );
  });

  // Draw gap lines
  const lines = colours.map((_, index) => {
    const angle = segmentAngle * index + rotationOffset;
    const rad = (angle * Math.PI) / 180;

    // Line endpoint on the circle
    const lineX = radius + radius * Math.cos(rad);
    const lineY = radius + radius * Math.sin(rad);

    return (
      <line
        key={`line-${index}`}
        x1={radius}
        y1={radius}
        x2={Math.round(lineX)}
        y2={Math.round(lineY)}
        stroke={gapColor}
        strokeWidth={gapWidth}
        strokeLinecap="round"
      />
    );
  });

  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
    >
      {paths}
      {lines}
    </svg>
  );
}

function SplitCircleIndicator({
  colours, labels, size, isLoading,
}) {
  const loadingColour = '#ccc';

  return (
    <Flex align="center" justify="center" position="relative" width="100%" className="indicator">
      <Box pos="relative" py="0px" px="32px">
        {isLoading && (
          <ChartSvg colours={[loadingColour, loadingColour, loadingColour]} size={size} />
        )}
        {!isLoading && colours && (
          <ChartSvg colours={colours} size={size} />
        )}
      </Box>
      {/* Labels */}
      {!isLoading && labels && (
      <>
        <Text
          position="absolute"
          textAlign="center"
          fontSize="9px"
          top="-12px"
          left="50%"
          transform="translate(-50%, 0px)"
          width="max-content"
        >
          {labels[0]}
        </Text>
        <Text
          position="absolute"
          right="50%"
          fontSize="9px"
          bottom="12px"
          textAlign="left"
          transformOrigin="center left"
          transform={`translateX(${size + 95}%)`}
        >
          {labels[1]}
        </Text>
        <Text
          position="absolute"
          fontSize="9px"
          bottom="12px"
          left="50%"
          textAlign="right"
          transformOrigin="center right"
          transform={`translateX(-${size + 120}%)`}
        >
          {labels[2]}
        </Text>
      </>
      )}
    </Flex>
  );
}

export default SplitCircleIndicator;
