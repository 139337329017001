import { useRef } from 'react';
import {
  Box, Flex, Heading, Icon, IconButton, Link, LinkBox,
  LinkOverlay, Modal, ModalBody, ModalCloseButton, ModalContent,
  ModalHeader, ModalOverlay, Text, Tooltip, useDisclosure, useDimensions,
  useToast,
} from '@chakra-ui/react';
import { selectedDatabaseIdState, useIsUserInRole } from '@transport-insights/uikit';
import {
  FiArrowLeft, FiArrowRight, FiGrid, FiLink2, FiList,
} from 'react-icons/fi';
import { Link as RouterLink, Redirect } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { useAllowedReportsList } from '../hooks/useReportsList';
import { customDashboardLayoutState } from '../state';
import StateHighwaysSubnetworkSelector from './StateHighwaysSubnetworkSelector';

export default function Pagination({
  currentReportUrl, breadcrumbs, pdfPrint = null, children,
}) {
  const hasDeveloperRole = useIsUserInRole('Developer');
  const { isOpen: isReportListOpen, onOpen: onOpenReportList, onClose: onCloseReportList } = useDisclosure();
  const { isOpen: isLinkListOpen, onOpen: onOpenLinkList, onClose: onCloseLinkList } = useDisclosure();
  const ref = useRef();
  const dimension = useDimensions(ref, true);
  const toast = useToast();

  const customDashboardLayout = useRecoilValue(customDashboardLayoutState);
  const rcaId = useRecoilValue(selectedDatabaseIdState);
  const reportsList = useAllowedReportsList(rcaId);

  // Order reports based on the customDashboardLayout order
  const orderedReportsList = customDashboardLayout?.widgetList.reduce((obj, key) => {
    if (key in reportsList) {
      obj[key] = reportsList[key];
    }
    return obj;
  }, {});

  // Add reports from reportsList that are not in customDashboardLayout.widgetList
  Object.keys(reportsList).forEach((key) => {
    if (!(key in orderedReportsList)) {
      orderedReportsList[key] = reportsList[key];
    }
  });

  // Filter reports list to only enabled reports and reports with a url
  // Check if devModeReport is true and verify hasDeveloperRole, otherwise show the report
  const enabledReportsList = Object.values(orderedReportsList).filter((x) => x.enabled
      && x.url
      && (x.devModeReport ? hasDeveloperRole : true));
  const currentReport = Object.values(reportsList).find((x) => x.url === currentReportUrl && x.enabled && x.url && (x.devModeReport ? hasDeveloperRole : true));

  const prevIndex = enabledReportsList.findIndex((report) => report.url === currentReportUrl) - 1;
  const nextIndex = enabledReportsList.findIndex((report) => report.url === currentReportUrl) + 1;

  const nextReport = nextIndex !== enabledReportsList.length ? enabledReportsList[nextIndex] : enabledReportsList[0];
  const prevReport = prevIndex !== -1 ? enabledReportsList[prevIndex] : enabledReportsList[enabledReportsList.length - 1];

  const isScrolling = dimension?.borderBox.top === 80;

  // If currentReport is undefined it means it is not available for the selected RCA
  // Redirect to the dashboard with a message
  if (!currentReport) {
    toast({
      title: 'Report Unavailable',
      description: 'Sorry the report is not available for the selected RCA',
      status: 'info',
      duration: 4000,
      isClosable: true,
    });
    return <Redirect to="/performance/dashboard" />;
  }

  return (
    <Box
      position="sticky"
      top="80px"
      pb={2}
      px={4}
      mx="-16px"
      background="gray.50"
      zIndex="250"
      ref={ref}
      boxShadow={isScrolling ? '1px 7px 10px -9px rgba(0,0,0,0.15);' : 'none'}
    >
      <Flex align="center" justify="space-between">
        <Flex align="center">
          <Tooltip label="Back to Dashboard">
            <IconButton
              as={RouterLink}
              to="/performance/dashboard"
              borderRadius="full"
              colorScheme="gray"
              color="gray.600"
              icon={<FiGrid />}
            />
          </Tooltip>
          <StateHighwaysSubnetworkSelector ml={4} />
          {breadcrumbs && breadcrumbs}
        </Flex>
        <Flex align="center" justify="space-between">
          {prevReport
            && (
            <Tooltip label={prevReport.name}>
              <Link
                as={RouterLink}
                to={`/performance/dashboard/${prevReport.url}`}
                color="brand.orange.500"
                display="flex"
                align="center"
                borderRadius="sm"
                p={2}
                _hover={{
                  textDecoration: 'none',
                  color: 'white',
                  background: 'brand.orange.500',
                }}
              >
                <Icon as={FiArrowLeft} boxSize={8} />
              </Link>
            </Tooltip>
            )}
          {nextReport
            && (
            <Tooltip label={nextReport.name}>
              <Link
                as={RouterLink}
                to={`/performance/dashboard/${nextReport.url}`}
                color="brand.orange.500"
                display="flex"
                align="center"
                borderRadius="sm"
                p={2}
                _hover={{
                  textDecoration: 'none',
                  color: 'white',
                  background: 'brand.orange.500',
                }}
              >
                <Icon as={FiArrowRight} boxSize={8} />
              </Link>
            </Tooltip>
            )}
          <Tooltip label="Reports List">
            <IconButton onClick={onOpenReportList} icon={<FiList />} borderRadius="full" colorScheme="gray" ml={3} />
          </Tooltip>
          <Tooltip label="Relevant Links">
            <IconButton onClick={onOpenLinkList} icon={<FiLink2 />} borderRadius="full" colorScheme="gray" ml={3} />
          </Tooltip>
          {pdfPrint}
        </Flex>
      </Flex>
      {children}
      <Modal isOpen={isReportListOpen} onClose={onCloseReportList} size="xl">
        <ModalOverlay
          bg="blackAlpha.700"
          backdropFilter="blur(4px)"
        />
        <ModalContent>
          <ModalHeader>Performance Reports</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {enabledReportsList.map((report) => (
              <Box key={report.url}>
                <LinkBox
                  as="article"
                  p={3}
                  borderRadius="md"
                  border="2px solid"
                  borderColor={report.url === currentReportUrl ? 'brand.orange.500' : 'gray.50'}
                  backgroundColor={report.url === currentReportUrl ? 'brand.orange.500' : 'white'}
                  mb={4}
                  transition="all 200ms"
                  _hover={{ borderColor: 'brand.orange.500' }}
                >
                  <Heading size="sm">
                    <LinkOverlay
                      as={RouterLink}
                      to={`/performance/dashboard/${report.url}`}
                      onClick={onCloseReportList}
                      color={report.url === currentReportUrl ? 'white' : 'gray.800'}
                      fontWeight="normal"
                      textDecoration="none"
                    >
                      {report.name}
                    </LinkOverlay>
                  </Heading>
                </LinkBox>
                {(report.url === currentReportUrl && report.subReports) && report.subReports.map((subReport) => subReport.enabled
                    && (
                    <LinkBox
                      as="article"
                      p={2}
                      borderRadius="md"
                      border="2px solid"
                      borderColor={subReport.url === currentReportUrl ? 'brand.orange.500' : 'gray.50'}
                      backgroundColor={subReport.url === currentReportUrl ? 'brand.orange.500' : 'white'}
                      mb={4}
                      ml={4}
                      transition="all 200ms"
                      _hover={{ borderColor: 'brand.orange.500' }}
                      key={subReport.url}
                    >
                      <Heading size="xs">
                        <LinkOverlay
                          as={RouterLink}
                          to={`/performance/dashboard/${subReport.url}`}
                          onClick={onCloseReportList}
                          color={subReport.url === currentReportUrl ? 'white' : 'gray.800'}
                          fontWeight="normal"
                          textDecoration="none"
                        >
                          {subReport.name}
                        </LinkOverlay>
                      </Heading>
                    </LinkBox>
                    ))}
              </Box>
            ))}
          </ModalBody>
        </ModalContent>
      </Modal>
      <Modal isOpen={isLinkListOpen} onClose={onCloseLinkList} size="xl">
        <ModalOverlay
          bg="blackAlpha.700"
          backdropFilter="blur(4px)"
        />
        <ModalContent>
          <ModalHeader>Relevant Links</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            {(currentReport?.relevantLinks?.length === 0 || !currentReport?.relevantLinks)
              && <Text>None listed</Text>}
            {currentReport?.relevantLinks?.length > 0 && currentReport?.relevantLinks.map((link) => (
              <Link
                as={RouterLink}
                to={link.url}
                fontSize="sm"
                key={link.url}
                display="block"
                py={1}
              >
                {link.title}
              </Link>
            ))}
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
}
