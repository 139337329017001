import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Text,
  Button,
  useDisclosure,
  VStack,
  HStack,
  Grid,
  GridItem,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Tbody,
  Table,
  Th,
  Tr,
  Td,
  Thead,
  Switch,
  Heading,
  TableContainer,
} from '@chakra-ui/react';
import { useState } from 'react';

const DUMMY_DATA = [
  {
    id: 1,
    groupName: 'Urban - Main Roads',
    description: 'Traffic group for major urban roads.',
    growthRatePercent: 3.2,
    heavyPercentage: 15.5,
    countSites: [
      {
        id: 1,
        roadName: 'Main Street',
        linearLocation: 120,
        previousADT: 15000,
        latestCount: 15500,
        latestCountDate: '2024-05-15',
        derivedFrom: 'Measured Flow and Trend Analysis',
        heavyPercentage: 16.2,
        counts: [
          {
            id: 1,
            countDate: '2023-05-10',
            count: 15000,
            isExcluded: false,
            heavyPercentage: 16.0,
            carPercentage: 70.0,
            lcvPercentage: 10.0,
            mcvPercentage: 5.0,
            hcviPercentage: 3.0,
            hcviiPercentage: 1.0,
            busesPercentage: 1.0,
          },
          {
            id: 2,
            countDate: '2022-04-18',
            count: 14500,
            isExcluded: true,
            heavyPercentage: 15.8,
            carPercentage: 71.0,
            lcvPercentage: 9.5,
            mcvPercentage: 4.5,
            hcviPercentage: 3.0,
            hcviiPercentage: 1.2,
            busesPercentage: 0.8,
          },
        ],
      },
      {
        id: 2,
        roadName: 'Broadway',
        linearLocation: 250,
        previousADT: 18000,
        latestCount: 18500,
        latestCountDate: '2024-05-15',
        derivedFrom: 'Measured Flow and Trend Analysis',
        heavyPercentage: 15.7,
        counts: [
          {
            id: 3,
            countDate: '2023-05-10',
            count: 18000,
            isExcluded: false,
            heavyPercentage: 15.5,
            carPercentage: 72.0,
            lcvPercentage: 9.0,
            mcvPercentage: 4.0,
            hcviPercentage: 2.5,
            hcviiPercentage: 1.0,
            busesPercentage: 1.5,
          },
          {
            id: 4,
            countDate: '2022-04-18',
            count: 17500,
            isExcluded: false,
            heavyPercentage: 15.3,
            carPercentage: 73.0,
            lcvPercentage: 8.5,
            mcvPercentage: 3.5,
            hcviPercentage: 2.0,
            hcviiPercentage: 1.2,
            busesPercentage: 1.8,
          },
        ],
      },
    ],
  },
  {
    id: 2,
    groupName: 'Rural - Low Volume',
    description: 'Traffic group for rural roads with low vehicle volumes.',
    growthRatePercent: 1.8,
    heavyPercentage: 22.3,
    countSites: [
      {
        id: 2,
        roadName: 'Country Road',
        linearLocation: 4500,
        previousADT: 2500,
        latestCount: 2600,
        latestCountDate: '2024-04-22',
        derivedFrom: 'Measured Flow and Trend Analysis',
        heavyPercentage: 23.1,
        counts: [
          {
            id: 3,
            countDate: '2023-04-12',
            count: 2550,
            isExcluded: false,
            heavyPercentage: 23.0,
            carPercentage: 60.0,
            lcvPercentage: 12.0,
            mcvPercentage: 10.0,
            hcviPercentage: 5.0,
            hcviiPercentage: 3.0,
            busesPercentage: 2.0,
          },
          {
            id: 4,
            countDate: '2021-03-20',
            count: 2400,
            isExcluded: false,
            heavyPercentage: 22.5,
            carPercentage: 61.0,
            lcvPercentage: 11.5,
            mcvPercentage: 9.5,
            hcviPercentage: 5.5,
            hcviiPercentage: 3.0,
            busesPercentage: 2.5,
          },
        ],
      },
    ],
  },
];

function TrafficGroupAudit({ lockYear, importId }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [activeGroupId, setActiveGroupId] = useState(DUMMY_DATA[0].id);
  const activeGroup = DUMMY_DATA.find((group) => group.id === activeGroupId) || null;
  const [expandedIndices, setExpandedIndices] = useState([]);

  const expandAll = () => setExpandedIndices(activeGroup?.countSites.map((_, index) => index));
  const collapseAll = () => setExpandedIndices([]);

  return (
    <>
      <Button onClick={onOpen} size="sm">Traffic Group Audit</Button>
      <Modal isOpen={isOpen} onClose={onClose} size="6xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            Traffic Group Audit for
            {' '}
            {`${lockYear} #${importId}`}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <Grid
              gridGap={4}
              templateColumns="1fr 3fr"
            >
              <GridItem>
                <VStack
                  align="flex-start"
                  spacing={2}
                >
                  {DUMMY_DATA.map((group) => (
                    <VStack
                      onClick={() => setActiveGroupId(group.id)}
                      align="flex-start"
                      cursor="pointer"
                      px={3}
                      py={2}
                      w="100%"
                      borderRadius="base"
                      bgColor={activeGroupId === group.id ? 'brand.orange.500' : 'gray.50'}
                      color={activeGroupId === group.id ? 'whiteAlpha.900' : 'gray.700'}
                      _hover={{
                        bgColor: activeGroupId === group.id ? 'brand.orange.500' : 'gray.100',
                      }}
                      transition="all 0.2s"
                      key={group.id}
                      spacing={0}
                    >
                      <Text
                        fontSize="sm"
                        color="inherit"
                        fontWeight="bold"
                      >
                        {group.groupName}
                      </Text>
                    </VStack>
                  ))}
                </VStack>
              </GridItem>
              <GridItem>
                <HStack
                  width="100%"
                  justify="space-between"
                  align="center"
                  mb={4}
                >
                  <VStack align="flex-start" spacing={1}>
                    <Heading as="h2" size="md" mb={0}>
                      {activeGroup?.groupName}
                    </Heading>
                    <Text color="gray.500" pb={0}>
                      {activeGroup?.description}
                    </Text>
                  </VStack>
                  <HStack spacing={2}>
                    <VStack
                      spacing={0}
                      border="1px solid"
                      borderRadius="base"
                      borderColor="gray.100"
                      py={1}
                      px={2}
                    >
                      <Text fontSize="xl" fontWeight="bold">
                        {activeGroup?.growthRatePercent}
                        %
                      </Text>
                      <Text fontSize="xs" color="gray.500">Growth Rate</Text>
                    </VStack>
                    <VStack
                      spacing={0}
                      border="1px solid"
                      borderRadius="base"
                      borderColor="gray.100"
                      py={1}
                      px={2}
                    >
                      <Text fontSize="xl" fontWeight="bold">
                        {activeGroup?.heavyPercentage}
                        %
                      </Text>
                      <Text fontSize="xs" color="gray.500">Heavy</Text>
                    </VStack>
                  </HStack>
                </HStack>
                {expandedIndices.length > 1 ? (

                  <Button
                    size="xs"
                    colorScheme="gray"
                    variant="outline"
                    mb={4}
                    onClick={collapseAll}
                  >
                    Collapse all Roads
                  </Button>
                ) : (
                  <Button
                    size="xs"
                    colorScheme="gray"
                    variant="outline"
                    mb={4}
                    onClick={expandAll}
                  >
                    Expand all Roads
                  </Button>
                )}
                <Accordion
                  allowMultiple
                  key={activeGroupId}
                  borderColor="gray.100"
                  index={expandedIndices}
                  onChange={(indices) => setExpandedIndices(indices)}
                >
                  {activeGroup?.countSites.map((site) => (
                    <AccordionItem key={site.id}>
                      <AccordionButton
                        pl={2}
                        py={2}
                        _expanded={{
                          bg: 'gray.50', color: 'brand.orange.500', borderBottom: '1px solid', borderColor: 'gray.100',
                        }}
                      >
                        <AccordionIcon mr={2} />
                        <Text
                          as="span"
                          flex="1"
                          textAlign="left"
                          fontWeight="bold"
                          color="inherit"
                        >
                          {site.roadName}
                        </Text>
                        <HStack>
                          <HStack align="center">
                            <Text
                              as="span"
                              fontSize="sm"
                              fontWeight="bold"
                              color="gray.700"
                            >
                              {site.latestCount.toLocaleString('en-NZ')}
                            </Text>
                            <Text
                              as="span"
                              fontSize="xs"
                              color="gray.500"
                            >
                              (
                              {new Date(site.latestCountDate).toLocaleDateString('en-NZ')}
                              )
                            </Text>
                            <Text
                              as="span"
                              fontSize="sm"
                              color="gray.700"
                              fontWeight="bold"
                            >
                              {site.heavyPercentage}
                              %
                            </Text>
                            <Text
                              as="span"
                              fontSize="xs"
                              color="gray.500"
                            >
                              (Heavy)
                            </Text>
                          </HStack>
                        </HStack>
                      </AccordionButton>
                      <AccordionPanel p={0}>
                        <HStack p={2} px={4} justify="flex-end">
                          <Text fontSize="xs">
                            Linear Location:
                            {' '}
                            <Text as="span" fontWeight="bold">
                              {site.linearLocation}
                              m
                            </Text>
                          </Text>
                          <Text fontSize="xs">
                            Derived from:
                            {' '}
                            <Text as="span" fontWeight="bold">
                              {site.derivedFrom}
                            </Text>
                          </Text>
                          <Text fontSize="xs">
                            Previous ADT:
                            {' '}
                            <Text as="span" fontWeight="bold">
                              {site.previousADT.toLocaleString('en-NZ')}
                            </Text>
                          </Text>
                        </HStack>
                        <Accordion
                          allowMultiple
                          borderColor="gray.100"
                        >
                          {site.counts.map((count) => (
                            <AccordionItem key={count.id}>
                              <AccordionButton py={2} pl={8}>
                                <AccordionIcon mr={2} />
                                <Text
                                  as="span"
                                  flex="1"
                                  fontSize="sm"
                                  textAlign="left"
                                  fontWeight="bold"
                                >
                                  {new Date(count.countDate).toLocaleDateString('en-NZ')}
                                </Text>
                                <HStack>
                                  <Text
                                    fontSize="sm"
                                    fontWeight="bold"
                                  >
                                    {count.count.toLocaleString('en-NZ')}
                                  </Text>
                                  <Switch
                                    id="enabled"
                                    title={count.isExcluded ? 'Excluded from count' : 'Included in count'}
                                    size="sm"
                                    isChecked={count.isExcluded}
                                    colorScheme="red"
                                    sx={{ 'span.chakra-switch__track:not([data-checked])': { backgroundColor: 'green.500' } }}
                                  />
                                </HStack>
                              </AccordionButton>
                              <AccordionPanel pl={8} pb={2} borderTop="1px solid" borderColor="gray.100">
                                <TableContainer
                                  border="1px solid"
                                  borderColor="gray.100"
                                  borderRadius="md"
                                  overflow="hidden"
                                >
                                  <Table size="sm">
                                    <Thead>
                                      <Tr>
                                        <Th>Heavy</Th>
                                        <Th>Car</Th>
                                        <Th>LCV</Th>
                                        <Th>MCV</Th>
                                        <Th>HCVI</Th>
                                        <Th>HCVII</Th>
                                        <Th>Buses</Th>
                                      </Tr>
                                    </Thead>
                                    <Tbody>
                                      <Tr>
                                        <Td borderBottom="none">
                                          {count.heavyPercentage}
                                          %
                                        </Td>
                                        <Td borderBottom="none">
                                          {count.carPercentage}
                                          %
                                        </Td>
                                        <Td borderBottom="none">
                                          {count.lcvPercentage}
                                          %
                                        </Td>
                                        <Td borderBottom="none">
                                          {count.mcvPercentage}
                                          %
                                        </Td>
                                        <Td borderBottom="none">
                                          {count.hcviPercentage}
                                          %
                                        </Td>
                                        <Td borderBottom="none">
                                          {count.hcviiPercentage}
                                          %
                                        </Td>
                                        <Td borderBottom="none">
                                          {count.busesPercentage}
                                          %
                                        </Td>
                                      </Tr>
                                    </Tbody>
                                  </Table>
                                </TableContainer>
                              </AccordionPanel>
                            </AccordionItem>
                          ))}
                        </Accordion>
                      </AccordionPanel>
                    </AccordionItem>
                  ))}
                </Accordion>
              </GridItem>
            </Grid>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default TrafficGroupAudit;
