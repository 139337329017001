import {
  Box,
  Container,
  Flex,
  Text,
} from '@chakra-ui/react';
import MainLayout from '@src/components/layouts/main';
import { TrafficLightColours as COLOURS } from '@src/modules/performance/shared/constants';
import { useServicePerformance, useServicePerformanceKPI } from '@src/modules/performance/context/reports-api-hooks';
import { useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Pagination from '../../components/Pagination';
import ReportCard from '../../components/ReportCard';
import InfoContentCollapse from './components/information/InfoContentCollapse';
import InfoScroller from './components/information/InfoScroller';
import TrafficLightTable from './components/selfloading/TrafficLightTable';
import html from './help/ServicePerformance.html';
import { CircleIndicator } from '../../components/Indicators';
import { mapValueToColourRange, roundKpiValue } from '../../shared/helpers';
import PrintablePdf from '../../components/PrintablePdf';

const subheader = 'LGA Non-Financial Performance Measures';
const subtitle = 'Annual Targets Achieved';

function ServicePerformance() {
  const { pathname } = useLocation();
  const reportUrl = pathname.split('/').pop();
  const { data: servicePerformanceData, isLoadingKpi } = useServicePerformanceKPI();
  const servicePerformance = useServicePerformance();
  const colour = mapValueToColourRange(servicePerformanceData?.percentageTargetAchieved, 'servicePerformanceKpi');
  const printableRef = useRef();
  // this controls the expansion of the help section
  const [index, setIndex] = useState(null);

  const helpSectionRef = useRef(null);
  // triggered when the user clicks on the help icon in the title
  const expandHelpSection = () => {
    setIndex(0);
  };

  return (
    <MainLayout backgroundColor="gray.50">
      <Container maxW="full">
        <Pagination
          currentReportUrl={reportUrl}
          pdfPrint={(
            <PrintablePdf
              fileName="Service Performance"
              printableRef={printableRef}
              printableStyles={null}
            />
          )}
        />
        <Box ref={printableRef}>
          <ReportCard mt={2} pt={0}>
            <ReportCard.Header
              title="Service Performance"
              subtitle={subheader}
              info={<InfoScroller expandHelpSection={expandHelpSection} ref={helpSectionRef} />}
            >
              <Flex align="center">
                <Box mr={3}>
                  <CircleIndicator
                    size={60}
                    colour={COLOURS[colour]}
                    value={servicePerformanceData?.percentageTargetAchieved !== null && servicePerformanceData?.percentageTargetAchieved !== undefined ? `${roundKpiValue(servicePerformanceData?.percentageTargetAchieved, 5, 1)}%` : null}
                    isLoading={isLoadingKpi}
                    padding={0}
                  />
                </Box>
                <Box>
                  <Text fontWeight="bold" color="gray">
                    Non-financial performance
                    <br />
                    measure achievement
                  </Text>
                </Box>
              </Flex>
            </ReportCard.Header>
            <ReportCard.Body>
              <Text mb={5} textStyle="reportSectionHeader" className="report-section-header">{subtitle}</Text>
              <Box>
                <TrafficLightTable queryInfo={servicePerformance} />
              </Box>
            </ReportCard.Body>
          </ReportCard>
        </Box>
        <InfoContentCollapse
          ref={helpSectionRef}
          title="Information Sources"
          html={html}
          index={index}
          setIndex={setIndex}
        />
      </Container>
    </MainLayout>
  );
}

export default ServicePerformance;
