import { useEffect, useState } from 'react';
import { Box, Center, Text } from '@chakra-ui/react';
import LockYearBadge from '@src/modules/performance/components/LockYearBadge';
import AreaLineChart from '../charts/AreaLineChart';

function SLAreaLineChart({
  queryInfo, title1, title2, labels, colours, unitprefix, unitpostfix, areaIndex, ...props
}) {
  const [chart, setChart] = useState({
    grouping: [],
    labels,
    datasets: [],
    colours,
    unitprefix,
    unitpostfix,
    adjustYmax: null,
  });
  useEffect(() => {
    if (queryInfo?.data) {
      setChart({
        ...chart,
        grouping: queryInfo.data.years,
        datasets: queryInfo.data.values,
        adjustYmax: queryInfo.data.adjustYmax,
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryInfo]);

  if (!queryInfo?.data) {
    return null;
  }
  return (
    <Box>
      <Text
        align="center"
        fontSize={12}
        fontWeight="bold"
        className="chart-title"
      >
        {title1}
      </Text>
      <Text
        align="center"
        fontSize={12}
        fontWeight="bold"
        mb={5}
        className="chart-title"
      >
        {title2}
      </Text>
      <LockYearBadge lockYear={queryInfo?.data?.lockYear} />
      <Center>
        <AreaLineChart
          grouping={chart.grouping}
          labels={chart.labels}
          datasets={chart.datasets}
          colours={chart.colours}
          unitprefix={chart.unitprefix}
          unitpostfix={chart.unitpostfix}
          adjustYmax={chart.adjustYmax}
          areaIndex={areaIndex}
          {...props}
        />
      </Center>
    </Box>
  );
}

export default SLAreaLineChart;
