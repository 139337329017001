import { Text } from '@chakra-ui/react';
import Widget from './Widget';
import { useTerritorialActivityKPI } from '../../context/reports-api-hooks';
import { CircleIndicator } from '../Indicators';
import { roundKpiValue } from '../../shared/helpers';

export default function TerritorialActivityWidget() {
  const { data: territorialActivityData, isLoading, isError } = useTerritorialActivityKPI();

  const title = 'Territorial Activity';
  const description = 'Population growth over 5 years';

  // If there is an error and no data we want to hide the widget
  if (isError && !territorialActivityData) return null;

  return (
    <Widget
      link="/performance/dashboard/territorial-activity"
      title={title}
      description={description}
      lockYear={territorialActivityData?.lockYear}
      quarter={null}
      isLoading={isLoading && !territorialActivityData}
      moreInfoContent={
        <Text fontSize="sm">The average population growth per year for the last five years.</Text>
      }
    >
      <CircleIndicator
        size={100}
        colour={territorialActivityData?.percentage !== undefined ? 'gray.700' : null}
        value={territorialActivityData?.percentage !== undefined ? `${roundKpiValue(territorialActivityData?.percentage, 5, 1, 'max')}%` : undefined}
        isLoading={isLoading || !territorialActivityData}
      />
    </Widget>
  );
}
