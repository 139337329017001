import { useAuthenticatedApi } from '@transport-insights/uikit';
import { useConfig } from '@src/context/config';

export default () => {
  const { PERFORMANCE_API_URL } = useConfig();
  const api = useAuthenticatedApi(PERFORMANCE_API_URL);

  return {
    async getCurrentTtmEntryPeriod(rcaId) {
      const url = `/temporary-traffic-management/entry-period/${rcaId}`;
      const res = await api.get(url);
      return res?.data || undefined;
    },
    async getTtmList(rcaId) {
      const url = `/temporary-traffic-management/rca/${rcaId}`;
      const res = await api.get(url);
      return res?.data || [];
    },
    async getTemporaryTrafficManagementKpi(rcaId) {
      const url = `/temporary-traffic-management/kpi/${rcaId}`;
      const res = await api.get(url);
      return res?.data || [];
    },
    async getTtmReport(lockYear, quarter) {
      const url = `/temporary-traffic-management/report/${lockYear}/${quarter}`;
      const res = await api.get(url);
      return res?.data || undefined;
    },
    async generateTtmReport(lockYear, quarter) {
      const convertedLockYear = lockYear?.replace('/', '-');
      const url = `/temporary-traffic-management/report/${convertedLockYear}/${quarter}/download`;
      const res = await api.get(url);
      return res?.data || undefined;
    },
    async downloadTtmReport(lockYear, quarter, reportId) {
      const convertedLockYear = lockYear?.replace('/', '-');
      const url = `/temporary-traffic-management/report/${convertedLockYear}/${quarter}/download/${reportId}`;
      const res = await api.get(url);
      return res?.data || undefined;
    },
    async getTtmReportOptions() {
      const url = '/temporary-traffic-management/report/options';
      const res = await api.get(url);
      return res?.data || undefined;
    },
    async createTtmResult(ttmResult) {
      const { isTestMode } = ttmResult;
      delete ttmResult.isTestMode;
      const res = await api.post(`/temporary-traffic-management${isTestMode ? '?force=true' : ''}`, { ...ttmResult }, {
        responseType: 'json',
      });
      return res?.data || null;
    },
    async updateTtmResult(ttmResult) {
      const { isTestMode } = ttmResult;
      delete ttmResult.isTestMode;
      const res = await api.put(`/temporary-traffic-management${isTestMode ? '?force=true' : ''}`, { ...ttmResult }, {
        responseType: 'json',
      });
      return res?.data || null;
    },
    async activateTtmResults({ quarterNumber, lockYear }) {
      const res = await api.post('/temporary-traffic-management/report/publish', { quarterNumber, lockYear }, {
        responseType: 'json',
      });
      return res?.data || null;
    },
    async deactivateTtmResults({ quarterNumber, lockYear }) {
      const res = await api.post('/temporary-traffic-management/report/unpublish', { quarterNumber, lockYear }, {
        responseType: 'json',
      });
      return res?.data || null;
    },
    async createTtmSiteInspection(ttmSiteInspection) {
      const res = await api.post('/site-inspection', { ...ttmSiteInspection });
      return res;
    },
    async getTtmSiteInspectionList({ rcaId, startDate, endDate }) {
      const url = `/site-inspection/${rcaId}`;
      const res = await api.post(url, { startDate, endDate });
      return res?.data || null;
    },
    async getTemporaryTrafficManagementCostPercentTrend(rcaId) {
      const url = `/temporary-traffic-management/cost-percent-trend/${rcaId}`;
      const res = await api.get(url);
      return res?.data || [];
    },
    async getTemporaryTrafficManagementCostConfidence(rcaId) {
      const url = `/temporary-traffic-management/cost-confidence/${rcaId}`;
      const res = await api.get(url);
      console.log(res?.data);
      return res?.data || {};
    },
  };
};
