import {
  Box, Container, Flex,
  Text,
  Table, Thead, Tbody, Tr, Th, Td,
  Wrap,
  Spinner,
} from '@chakra-ui/react';
import Chart from 'react-apexcharts';
import MainLayout from '@src/components/layouts/main';
import { QUARTERS, TrafficLightColours } from '@src/modules/performance/shared/constants';
import { useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { useRecoilValue } from 'recoil';
import { currentDatabaseSelector } from '@transport-insights/uikit';
import Pagination from '../../components/Pagination';
import ReportCard from '../../components/ReportCard';
import InfoContentCollapse from './components/information/InfoContentCollapse';
import InfoScroller from './components/information/InfoScroller';
import { roundKpiValue } from '../../shared/helpers';

import IndicatorLegend from '../../components/IndicatorLegend';
import { useTemporaryTrafficManagementCostConfidence, useTemporaryTrafficManagementCostPercentTrend, useTemporaryTrafficManagementKpi } from '../../context/temporary-traffic-management-api-hooks';
import { ChartColours } from './components/charts/ChartColours';

const subheader = 'TTM cost as a percentage of NLTP';

function TemporaryTrafficManagement() {
  const { pathname } = useLocation();
  const reportUrl = pathname.split('/').pop();
  const currentDatabase = useRecoilValue(currentDatabaseSelector);

  // KPI data
  const { data: ttmKpiData, isLoading: isLoadingKpi } = useTemporaryTrafficManagementKpi();

  // Cost Percentage Trend data
  const { data: costPercentTrend, isLoading: isLoadingCostPercentTrend } = useTemporaryTrafficManagementCostPercentTrend();
  const costPercentTrendQuarters = [
    ...new Set(costPercentTrend?.map((item) => `Q${item.quarter} ${item.lockYear}`)),
  ].sort();
  const costPercentTrendChartData = [
    {
      name: currentDatabase?.name,
      type: 'line',
      data: costPercentTrend?.map((item, index) => ({ x: costPercentTrendQuarters[index], y: item.costPercent })),
    },
    {
      name: 'Peer Group',
      type: 'line',
      data: costPercentTrend?.map((item, index) => ({ x: costPercentTrendQuarters[index], y: item.peerGroupCostPercent })),
    },
    {
      name: '25-75th Percentile',
      type: 'rangeArea',
      data: costPercentTrend?.map((item, index) => ({ x: costPercentTrendQuarters[index], y: [item.national25thPercentile, item.national75thPercentile] })),
    },
  ];

  // Cost confidence data
  const { data: costConfidenceData, isLoading: isLoadingCostConfidence } = useTemporaryTrafficManagementCostConfidence();
  const costConfidenceQuarters = [
    ...new Set(costConfidenceData?.costConfidences?.map((item) => `Q${item.quarter} ${item.lockYear}`)),
  ].sort();
  const costConfidenceTrendData = [
    {
      name: currentDatabase?.name,
      type: 'line',
      data: costConfidenceData?.costConfidences?.map((item, index) => ({ x: costConfidenceQuarters[index], y: item.costConfidence })),
    },
    {
      name: '25-75th Percentile',
      type: 'rangeArea',
      data: costConfidenceData?.nationalPercentiles?.map((item, index) => ({ x: costConfidenceQuarters[index], y: [item.percentile25th, item.percentile75th] })),
    },
  ];

  const isLoading = isLoadingKpi || isLoadingCostPercentTrend || isLoadingCostConfidence;

  // this controls the expansion of the help section
  const [index, setIndex] = useState(null);
  const helpSectionRef = useRef(null);
  const expandHelpSection = () => {
    setIndex(0);
  };

  return (
    <MainLayout backgroundColor="gray.50">
      <Container maxW="full">
        <Pagination currentReportUrl={reportUrl} />
        <ReportCard mt={2} pt={0}>
          <ReportCard.Header title="Temporary Traffic Management" subtitle={subheader} info={<InfoScroller expandHelpSection={expandHelpSection} ref={helpSectionRef} />}>
            {ttmKpiData?.value && ttmKpiData?.value !== null && !isLoading && (
              <Flex align="center" ml={4}>
                <Box mr={3}>
                  <Text
                    colour={ttmKpiData?.value !== undefined ? 'gray.700' : null}
                    fontSize={36}
                    textAlign="center"
                  >
                    {roundKpiValue(ttmKpiData?.value, 7, 1)}
                    %
                  </Text>
                </Box>
                <Box>
                  <Text fontWeight="bold" color="gray" fontSize="sm">
                    NLTP Expenditure (%)
                    <br />
                    <Text as="span" fontSize="xs" color="gray">
                      as at
                      {' '}
                      {QUARTERS.find((q) => q.id === parseInt(ttmKpiData?.quarter, 10))?.label}
                      {' '}
                      {ttmKpiData?.quadLockYear}
                    </Text>
                  </Text>
                </Box>
              </Flex>
            )}
          </ReportCard.Header>
          <ReportCard.Body>
            {isLoading && <Flex justify="center" align="center" h="300px"><Spinner color="brand.orange.500" size="lg" /></Flex>}
            {!isLoading && (
              <Wrap spacing={4}>
                <Box>
                  <Text mb={5} textStyle="reportSectionHeader" className="report-section-header">TTM cost as a percentage of NLTP</Text>
                  <Chart
                    options={
                        {
                          chart: {
                            animations: {
                              enabled: false,
                            },
                          },
                          xaxis: {
                            categories: costPercentTrendQuarters,
                            labels: {
                              offsetY: 0,
                              rotate: -45,
                              rotateAlways: true,
                            },
                            tickPlacement: 'between',
                          },
                          yaxis: [
                            {
                              forceNiceScale: true,
                              title: {
                                text: 'NLTP Expenditure (%)',
                              },
                              labels: {
                                formatter(y) {
                                  if (typeof y !== 'undefined') {
                                    return `${y.toFixed(0)}%`;
                                  }
                                  return y;
                                },
                                offsetX: 30,
                              },
                            },
                          ],
                          grid: {
                            padding: {
                              left: 50,
                            },
                          },
                          dataLabels: {
                            enabled: false,
                          },
                          stroke: {
                            curve: 'straight',
                            width: [2, 2, 0],
                          },
                          colors: [ChartColours.blue, ChartColours.black, ChartColours.lightgrey],
                          fill: {
                            colors: [ChartColours.blue, ChartColours.black, ChartColours.lightgrey],
                          },
                          markers: {
                            size: [4, 4, 0],
                            hover: {
                              size: 0,
                            },
                          },
                          legend: {
                            show: true,
                            floating: true,
                            offsetY: 5,
                            itemMargin: {
                              vertical: 0,
                            },
                          },
                          tooltip: {
                            shared: true,
                            intersect: false,
                            custom({
                              dataPointIndex, w,
                            }) {
                              const { initialSeries } = w.globals;
                              const values = initialSeries.map((s) => {
                                const seriesName = s.name;
                                const value = s.data[dataPointIndex].y;
                                // Check if the series is a range (array with min and max)
                                // Handle rangeArea (min and max values)
                                if (s.type === 'rangeArea' && Array.isArray(value)) {
                                  const [min, max] = value;
                                  return `<li><b>${seriesName}</b>: ${min?.toFixed(0)}% to ${max?.toFixed(0)}%</li>`;
                                }

                                // Standard series value
                                return `<li><b>${seriesName}</b>: ${value?.toFixed(0)}%</li>`;
                              });

                              return `
                                <div style="padding: 10px">
                                  <ul>${values.join('')}</ul>
                                </div>
                              `;
                            },
                          },
                          noData: {
                            offsetX: 30,
                          },
                        }
                    }
                    series={costPercentTrendChartData || []}
                    type="rangeArea"
                    width="100%"
                    height="400"
                  />
                </Box>
                <Box>
                  <Text mb={5} textStyle="reportSectionHeader" className="report-section-header">Confidence in TTM Cost</Text>
                  <Chart
                    options={
                        {
                          chart: {
                            animations: {
                              enabled: false,
                            },
                          },
                          xaxis: {
                            categories: costConfidenceQuarters,
                            labels: {
                              offsetY: 0,
                              rotate: -45,
                              rotateAlways: true,
                            },
                            tickPlacement: 'between',
                          },
                          yaxis: [
                            {
                              forceNiceScale: false,
                              min: 1,
                              max: 5,
                              title: {
                                text: 'Cost Confidence',
                              },
                              labels: {
                                formatter(y) {
                                  if (typeof y !== 'undefined') {
                                    return `${y}`;
                                  }
                                  return y;
                                },
                                offsetX: 30,
                              },
                            },
                          ],
                          grid: {
                            padding: {
                              left: 50,
                            },
                          },
                          dataLabels: {
                            enabled: false,
                          },
                          stroke: {
                            curve: 'straight',
                            width: [2, 0],
                          },
                          colors: [ChartColours.blue, ChartColours.lightgrey],
                          fill: {
                            colors: [ChartColours.blue, ChartColours.lightgrey],
                          },
                          markers: {
                            size: [4, 0],
                            hover: {
                              size: 0,
                            },
                          },
                          legend: {
                            show: true,
                            floating: true,
                            offsetY: 5,
                            itemMargin: {
                              vertical: 0,
                            },
                          },
                          tooltip: {
                            shared: true,
                            intersect: false,
                            custom({
                              dataPointIndex, w,
                            }) {
                              const { initialSeries } = w.globals;
                              const values = initialSeries.map((s) => {
                                const seriesName = s.name;
                                const value = s.data[dataPointIndex].y;
                                // Check if the series is a range (array with min and max)
                                // Handle rangeArea (min and max values)
                                if (s.type === 'rangeArea' && Array.isArray(value)) {
                                  const [min, max] = value;
                                  return `<li><b>${seriesName}</b>: ${min} to ${max}</li>`;
                                }

                                // Standard series value
                                return `<li><b>${seriesName}</b>: ${value}</li>`;
                              });

                              return `
                                <div style="padding: 10px">
                                  <ul>${values.join('')}</ul>
                                </div>
                              `;
                            },
                          },
                          noData: {
                            offsetX: 30,
                          },
                        }
                    }
                    series={costConfidenceTrendData || []}
                    type="rangeArea"
                    width="100%"
                    height="400"
                  />
                </Box>
              </Wrap>
            )}
          </ReportCard.Body>
        </ReportCard>
        <InfoContentCollapse
          ref={helpSectionRef}
          title="Information Sources"
          html={null}
          index={index}
          setIndex={setIndex}
        >
          <Box background="white" p={4} borderRadius={4}>
            <Table variant="simple" size="sm">
              <Thead>
                <Tr>
                  <Th width="25%">REPORT ELEMENT</Th>
                  <Th>NOTES</Th>
                  <Th width="20%">SOURCE</Th>
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  <Td colSpan={3}>
                    <Text fontWeight="bold" fontSize="md" color="brand.orange.500">State Highway Pothole Repairs</Text>
                  </Td>
                </Tr>
                <Tr verticalAlign="top">
                  <Td fontWeight="bold">% completed within 24 hours</Td>
                  <Td>
                    <Text mb={4}>
                      The indicator shows the colour grade for the percentage of potholes on all state highways that were repaired within 24 hours for the quarter:
                    </Text>
                    <IndicatorLegend items={[
                      { label: '>= 90%', color: TrafficLightColours.GREEN },
                      { label: '< 90%', color: TrafficLightColours.RED },
                    ]}
                    />
                  </Td>
                  <Td>Sourced from NZTA</Td>
                </Tr>
                <Tr>
                  <Td colSpan={3}>
                    <Text fontWeight="bold" fontSize="md" color="brand.orange.500">Targets for road categories</Text>
                  </Td>
                </Tr>
                <Tr verticalAlign="top">
                  <Td fontWeight="bold">
                    % completed within 24 hours for high-volume, national and arterial roads
                  </Td>
                  <Td>
                    <Text mb={4}>
                      The colour grade for the percentage of potholes on high-volume, national, and arterial state highways that were repaired within 24 hours for the quarter:
                    </Text>
                    <IndicatorLegend items={[
                      { label: '>= 95%', color: TrafficLightColours.GREEN },
                      { label: '< 95%', color: TrafficLightColours.RED },
                    ]}
                    />
                  </Td>
                  <Td>Sourced from NZTA</Td>
                </Tr>
                <Tr verticalAlign="top">
                  <Td fontWeight="bold">
                    % completed within 24 hours for regional, primary collector, and secondary collector roads
                  </Td>
                  <Td>
                    <Text mb={4}>
                      The colour grade for the percentage of potholes on regional, primary collector, and secondary collector state highways that were repaired within 24 hours for the quarter:
                    </Text>
                    <IndicatorLegend items={[
                      { label: '>= 85%', color: TrafficLightColours.GREEN },
                      { label: '< 85%', color: TrafficLightColours.RED },
                    ]}
                    />
                  </Td>
                  <Td>Sourced from NZTA</Td>
                </Tr>
                <Tr>
                  <Td colSpan={3}>
                    <Text fontWeight="bold" fontSize="md" color="brand.orange.500">Network Outcome Contracts</Text>
                  </Td>
                </Tr>
                <Tr verticalAlign="top">
                  <Td fontWeight="bold">% completed within 24 hours</Td>
                  <Td>
                    <Text>
                      The quarterly percentages of potholes on state highways within each Network Outcome Contract that were repaired within 24 hours.
                    </Text>
                  </Td>
                  <Td>Sourced from NZTA</Td>
                </Tr>
                <Tr>
                  <Td colSpan={3}>
                    <Text fontWeight="bold" fontSize="md" color="brand.orange.500">Regions</Text>
                  </Td>
                </Tr>
                <Tr verticalAlign="top">
                  <Td fontWeight="bold">% completed within 24 hours</Td>
                  <Td>
                    <Text fontSize="sm">
                      The quarterly percentages of potholes on state highways within each Region that were repaired within 24 hours.
                    </Text>
                  </Td>
                  <Td>Sourced from NZTA</Td>
                </Tr>
              </Tbody>
            </Table>
          </Box>
        </InfoContentCollapse>
      </Container>
    </MainLayout>
  );
}

export default TemporaryTrafficManagement;
