import {
  Flex, Grid, Box,
} from '@chakra-ui/react';
import { useConfig } from '@src/context/config';
import {
  SideNavigation, MainHeader, currentDatabaseSelector, selectedLockYearState, MainFooter,
} from '@transport-insights/uikit';
import { useRecoilValue } from 'recoil';

export default function MainLayout({
  showSidebar = true, isCustomising = false, children, ...other
}) {
  const db = useRecoilValue(currentDatabaseSelector);
  const lockYear = useRecoilValue(selectedLockYearState);
  const dbString = db?.id ? db.id.toString() : null;
  const { LEGACY_PMRT_URL, SETTINGS_API_URL } = useConfig();

  // Set global apex chart options
  window.Apex.chart = {
    toolbar: {
      show: true,
      tools: {
        download: true,
      },
    },
    zoom: {
      enabled: false,
    },
  };

  window.Apex.noData = {
    text: 'No Data',
    align: 'center',
    verticalAlign: 'middle',
    style: {
      fontSize: '16px',
    },
  };

  window.Apex.title = {
    style: {
      fontSize: '12px',
      fontWeight: 'bold',
      color: '#4a4453',
    },
  };

  return (
    <>
      <Box position="sticky" top="0" zIndex="550">
        {isCustomising && (
        <Box
          position="absolute"
          bg="blackAlpha.700"
          zIndex="555"
          top={0}
          right={0}
          bottom={0}
          left={0}
        />
        )}
        <MainHeader pmrtLegacyUrl={LEGACY_PMRT_URL} settingsApiUrl={SETTINGS_API_URL} loginUrl={process.env.REACT_APP_LOGIN_PATH} />
      </Box>
      <Grid
        templateColumns={{ base: 'minmax(0, auto) minmax(0, 1fr)', xl: 'minmax(0, 18rem) minmax(0, 1fr)' }}
      >
        {showSidebar && (
          <Box position="relative" zIndex="400">
            {isCustomising && (
            <Box
              position="absolute"
              zIndex="450"
              top={0}
              right={0}
              bottom={0}
              left={0}
              bg="blackAlpha.700"
            />
            )}
            <SideNavigation
              db={dbString}
              lockYear={lockYear}
              reactRouterApps={['reg-insights', 'tce']}
            />
          </Box>
        )}
        <Flex
          flexDir="column"
        >
          <Flex
            as="main"
            role="main"
            flexGrow="1"
            flexDirection="column"
            backgroundColor="gray.50"
            py={4}
            {...other}
          >
            { children }
          </Flex>
          <MainFooter />
        </Flex>
      </Grid>
    </>
  );
}
