import {
  Box,
  Container,
  Flex, HStack, Text,
  Wrap,
} from '@chakra-ui/react';
import MainLayout from '@src/components/layouts/main/MainLayout';
import {
  useBridgesCount,
  useCyclewaysNetworkLength,
  useNetworkCharacteristicsKPI,
  useRoadsNetworkLength, useRoadsUrbanLength,
} from '@src/modules/performance/context/reports-api-hooks';
import { useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Pagination from '../../components/Pagination';
import ReportCard from '../../components/ReportCard';
import InfoContentCollapse from './components/information/InfoContentCollapse';
import InfoScroller from './components/information/InfoScroller';
import SLLineChart from './components/selfloading/SLLineChart';
import SLStackedLineChart from './components/selfloading/SLStackedLineChart';
import html from './help/NetworkPhysicalCharacteristics.html';
import { CircleIndicator } from '../../components/Indicators';
import { roundKpiValue } from '../../shared/helpers';
import PrintablePdf from '../../components/PrintablePdf';

const subheader = 'Roads, Cycleways and Bridges';

const stackedColours = ['transblue', 'transgrey'];
const lineColours = [
  'blue',
  'orangered',
  'grey',
];

function NetworkPhysicalCharacteristics() {
  const { pathname } = useLocation();
  const reportUrl = pathname.split('/').pop();

  const { data: networkPhysicalCharacteristicsData, isLoadingKpi } = useNetworkCharacteristicsKPI();

  const roadsNetworkLength = useRoadsNetworkLength();
  const roadsUrbanLength = useRoadsUrbanLength();
  const cyclewaysNetworkLength = useCyclewaysNetworkLength();
  const bridgesCount = useBridgesCount();
  const printableRef = useRef();

  // this controls the expansion of the help section
  const [index, setIndex] = useState(null);

  const helpSectionRef = useRef(null);
  // triggered when the user clicks on the help icon in the title
  const expandHelpSection = () => {
    setIndex(0);
  };

  return (
    <MainLayout backgroundColor="gray.50">
      <Container maxW="full">
        <Pagination
          currentReportUrl={reportUrl}
          pdfPrint={(
            <PrintablePdf
              fileName="Network Physical Characteristics"
              printableRef={printableRef}
              printableStyles={null}
            />
          )}
        />
        <Box ref={printableRef}>
          <ReportCard mt={2} pt={0}>
            <ReportCard.Header
              title="Network Physical Characteristics"
              subtitle={subheader}
              info={<InfoScroller expandHelpSection={expandHelpSection} ref={helpSectionRef} />}
            >
              <Flex align="center">
                <HStack mr={3} spacing={0}>
                  <CircleIndicator
                    size={60}
                    colour={networkPhysicalCharacteristicsData?.percentage !== undefined ? 'gray.700' : null}
                    value={networkPhysicalCharacteristicsData?.percentage !== undefined ? `${roundKpiValue(networkPhysicalCharacteristicsData?.percentage, 5, 1, 'max')}%` : undefined}
                    isLoading={isLoadingKpi || !networkPhysicalCharacteristicsData}
                  />
                  <Text>
                    (
                    {networkPhysicalCharacteristicsData?.value}
                    km)
                  </Text>
                </HStack>
                <Text fontWeight="bold" color="gray">
                  Network Length Growth
                  <br />
                  <Text as="span" fontSize="xs" color="gray">
                    (
                    {networkPhysicalCharacteristicsData?.averageYearRange}
                    {' '}
                    Annual Avg)
                  </Text>
                </Text>
              </Flex>
            </ReportCard.Header>
            <ReportCard.Body>
              <Wrap spacing={4}>
                <Box>
                  <Text mb={5} textStyle="reportSectionHeader" className="report-section-header">Roads</Text>
                  <Flex alignContent="start" className="gap">
                    {/* two charts */}
                    <SLStackedLineChart
                      queryInfo={roadsNetworkLength}
                      title1="Network length (km)"
                      title2="&nbsp;"
                      labels={[
                        'Sealed',
                        'Unsealed',
                      ]}
                      colours={stackedColours}
                      unitprefix=""
                      unitpostfix=""
                    />
                    <SLLineChart
                      queryInfo={roadsUrbanLength}
                      title1="Urban % by length"
                      title2="&nbsp;"
                      labels={['Urban']}
                      colours={lineColours}
                      unitprefix=""
                      unitpostfix="%"
                      hideLegend
                      chartMin={0.0}
                      chartMax={100.0}
                    />
                  </Flex>
                </Box>

                <Box>
                  <Text mb={5} textStyle="reportSectionHeader" className="report-section-header">Cycleways</Text>
                  <Flex alignContent="start" className="gap">
                    {/* one chart */}
                    <SLStackedLineChart
                      queryInfo={cyclewaysNetworkLength}
                      title1="Network length (km)"
                      title2="&nbsp;"
                      labels={[
                        'Rural',
                        'Urban',
                      ]}
                      colours={stackedColours}
                      unitprefix=""
                      unitpostfix=""
                    />
                  </Flex>
                </Box>

                <Box>
                  <Text mb={5} textStyle="reportSectionHeader" className="report-section-header">Bridges</Text>
                  <Flex alignContent="start" className="gap">
                    {/* one chart */}
                    <SLLineChart
                      queryInfo={bridgesCount}
                      title1="No. bridges"
                      title2="&nbsp;"
                      labels={['Total', 'Timber', 'Single lane']}
                      colours={lineColours}
                      unitprefix=""
                      unitpostfix=""
                    />
                  </Flex>
                </Box>
              </Wrap>
            </ReportCard.Body>
          </ReportCard>
        </Box>
        <InfoContentCollapse
          ref={helpSectionRef}
          title="Information Sources"
          html={html}
          index={index}
          setIndex={setIndex}
        />
      </Container>
    </MainLayout>
  );
}

export default NetworkPhysicalCharacteristics;
