import {
  Box, Container, Flex,
  Text,
  Wrap,
} from '@chakra-ui/react';
import { TrafficLightColours as COLOURS } from '@src/modules/performance/shared/constants';
import MainLayout from '@src/components/layouts/main';
import { useAchievementsKPI } from '@src/modules/performance/context/map-api-hooks';
import {
  usePavementCondition,
  usePeakAverageRoadRoughness,
  useRideQualityRoughness,
  useSurfaceCondition,
} from '@src/modules/performance/context/reports-api-hooks';
import { useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Pagination from '../../components/Pagination';
import ReportCard from '../../components/ReportCard';
import InfoContentCollapse from './components/information/InfoContentCollapse';
import InfoScroller from './components/information/InfoScroller';
import SLAreaLineChart from './components/selfloading/SLAreaLineChart';
import html from './help/AmenityCondition.html';
import SLLineChart from './components/selfloading/SLLineChart';
import { CircleIndicator } from '../../components/Indicators';
import { roundKpiValue } from '../../shared/helpers';
import PrintablePdf from '../../components/PrintablePdf';

const subheader = 'Amenity and Road Condition';

const areaLineReportChartColours = [
  'blue', 'black', 'white', 'lightgrey',
];

const lineChartColours = [
  'red', 'blue', 'orange', 'seagreen', 'magenta', 'grey', 'black',
];

function AmenityCondition() {
  const { pathname } = useLocation();
  const reportUrl = pathname.split('/').pop();
  const { data: amenityData, isLoading } = useAchievementsKPI();
  const rideQualityRoughness = useRideQualityRoughness();
  const pavementCondition = usePavementCondition();
  const surfaceCondition = useSurfaceCondition();
  const peakAverageRoadRoughness = usePeakAverageRoadRoughness();
  const printableRef = useRef();
  // this controls the expansion of the help section
  const [index, setIndex] = useState(null);

  const helpSectionRef = useRef(null);
  // triggered when the user clicks on the help icon in the title
  const expandHelpSection = () => {
    setIndex(0);
  };

  return (
    <MainLayout backgroundColor="gray.50">
      <Container maxW="full">
        <Pagination
          currentReportUrl={reportUrl}
          pdfPrint={(
            <PrintablePdf
              fileName="Road Condition"
              printableRef={printableRef}
              printableStyles={null}
            />
          )}
        />
        <Box ref={printableRef}>
          <ReportCard mt={2} pt={0}>
            <ReportCard.Header title="Road Condition" subtitle={subheader} info={<InfoScroller expandHelpSection={expandHelpSection} ref={helpSectionRef} />}>
              <Flex align="center">
                <Box mr={3}>
                  <CircleIndicator
                    size={60}
                    colour={COLOURS[amenityData?.colour]}
                    value={`${roundKpiValue(amenityData?.value, 5, 1)}%`}
                    isLoading={isLoading && !amenityData}
                  />
                </Box>
                <Box>
                  <Text fontWeight="bold" color="gray">
                    Ride Quality
                    <br />
                    (STE) as at
                    {' '}
                    {amenityData?.lockYear}
                  </Text>
                </Box>
              </Flex>
            </ReportCard.Header>
            <ReportCard.Body>
              <Wrap spacing={4}>
                <Box>
                  <Text mb={5} textStyle="reportSectionHeader" className="report-section-header">Amenity (Sealed Roads)</Text>
                  <Flex flexDirection="row">
                    <SLAreaLineChart
                      queryInfo={rideQualityRoughness}
                      title1="Ride quality"
                      title2="(roughness of the roads)"
                      labels={[
                        'TA',
                        'Peer Group',
                        'top',
                        '25-75th National %ile',
                      ]}
                      colours={areaLineReportChartColours}
                      unitprefix=""
                      unitpostfix="%"
                    />
                    <SLLineChart
                      queryInfo={peakAverageRoadRoughness}
                      title1="Peak and average road roughness"
                      title2="(NAASRA)"
                      labels={[
                        '75th %ile',
                        'Peer 75th',
                        '85th %ile',
                        'Peer 85th',
                        'Average',
                        'Peer Average',
                        'National Average',
                      ]}
                      colours={lineChartColours}
                      unitprefix=""
                      unitpostfix=""
                    />
                  </Flex>
                </Box>
                <Box>
                  <Text mb={5} textStyle="reportSectionHeader" className="report-section-header">Road Condition (Sealed Roads)</Text>
                  <Flex flexDirection="row">
                    <SLAreaLineChart
                      queryInfo={pavementCondition}
                      title1="Pavement Condition"
                      title2="&nbsp;"
                      labels={[
                        'TA',
                        'Peer Group',
                        'top',
                        '25-75th National %ile',
                      ]}
                      colours={areaLineReportChartColours}
                      unitprefix=""
                      unitpostfix="%"
                    />
                    <SLAreaLineChart
                      queryInfo={surfaceCondition}
                      title1="Surface Condition"
                      title2="&nbsp;"
                      labels={[
                        'TA',
                        'Peer Group',
                        'top',
                        '25-75th National %ile',
                      ]}
                      colours={areaLineReportChartColours}
                      unitprefix=""
                      unitpostfix="%"
                    />
                  </Flex>
                </Box>
              </Wrap>
            </ReportCard.Body>
          </ReportCard>
        </Box>
        <InfoContentCollapse
          ref={helpSectionRef}
          title="Information Sources"
          html={html}
          index={index}
          setIndex={setIndex}
        />
      </Container>
    </MainLayout>
  );
}

export default AmenityCondition;
